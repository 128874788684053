<template>
  <MobileTutorial v-if="!showIntro" />
  <Transition name="fade">
    <QuizOpening v-if="showIntro" :text="$t('intro_text2')" />
  </Transition>
  <Transition name="fade">
    <TransitionScreen
      :text="questionTitle"
      v-if="showTransition"
      @finished-typing="showTransition = false"
      class="transition-page"
    />
  </Transition>
  <a-col class="quiz">
    <LottieAnimation
      v-if="!showIntro && questionIndex === 0"
      :height="400"
      width="80%"
      path="data-swipe.json"
      class="swipe-icon"
      loop
    />
    <GridBackground
      :questionNumber="questionIndex + 1"
      :completed="quizIsCompleted"
    />
    <Transition name="fade">
      <a-row
        align-center
        class="quiz__header"
        v-if="!showIntro && !showTransition && !quizIsCompleted"
      >
        <ARow class="quiz__arrow-container" justify-end align-center>
          <AnimatedArrow
            color="white"
            class="quiz__arrow"
            @click="previousQuestion"
            :activated="questionIndex !== 0"
          />
        </ARow>
        <a-text computer-font size="2vw" class="quiz__title">
          {{ questionTitle }}
        </a-text>
        <ARow class="quiz__arrow-container" align-center>
          <AnimatedArrow
            color="white"
            left
            class="quiz__arrow"
            @click="nextQuestion"
            :activated="wentBack"
          />
        </ARow>
      </a-row>
    </Transition>
    <Transition name="fade">
      <a-row
        justify-center
        align-center
        class="quiz__choices"
        gap="20vw"
        :style="{ opacity: !showTransition && !showIntro ? 1 : 0 }"
        v-if="!showIntro && !quizIsCompleted"
      >
        <question-choice
          v-bind="bindQuestionChoice(0)"
          @choice-clicked="choiceClicked(0)"
          firstChoice
          @mouseover="choiceIsHovered(0)"
          @mouseleave="resetHover()"
          :class="
            secondChoiceIsHovered || answeredQuestions[questionIndex] === 1
              ? 'quiz__choice--hover'
              : ''
          "
        />

        <question-choice
          v-bind="bindQuestionChoice(1)"
          @choice-clicked="choiceClicked(1)"
          @mouseover="choiceIsHovered(1)"
          @mouseleave="resetHover()"
          :class="
            firstChoiceIsHovered || answeredQuestions[questionIndex] === 0
              ? 'quiz__choice--hover'
              : ''
          "
        />
      </a-row>
    </Transition>
    <QuizCarousel
      class="quiz-carousel"
      :style="{ opacity: !showTransition && !showIntro ? 1 : 0 }"
      :bindQuestion="bindQuestionChoice"
      :choiceClick="choiceClicked"
      :initialSlide="initialSlide"
      v-if="!quizIsCompleted"
    />
    <a-row
      class="quiz__progress"
      v-if="!showIntro && !showTransition && !quizIsCompleted"
    >
      <ProgressBar
        :questionNumber="questionIndex + 1"
        :totalAnsweredQuestions="totalAnsweredQuestions"
      />
    </a-row>
  </a-col>
</template>
<script>
import AText from "@/components/AText.vue";
import ARow from "@/components/Flex/ARow.vue";
import ACol from "@/components/Flex/ACol.vue";
import AnimatedArrow from "@/components/Quiz/AnimatedArrow.vue";
import QuestionChoice from "@/components/Quiz/QuestionChoice.vue";
import ProgressBar from "@/components/Quiz/ProgressBar.vue";
import GridBackground from "@/components/Quiz/GridBackground.vue";
import { computed } from "@vue/reactivity";
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import QuizCarousel from "@/components/Quiz/QuizCarousel.vue";
import MobileTutorial from "@/components/Quiz/MobileTutorial.vue";
import QuizOpening from "@/components/Quiz/QuizOpening.vue";
import { useRouter } from "vue-router";
import { useActions, useGetters } from "vuex-composition-helpers";
import TransitionScreen from "@/components/Quiz/TransitionScreen.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
const resultsJson = require("@/misc/results.json");

export default {
  name: "quiz-view",
  components: {
    AText,
    ARow,
    ACol,
    AnimatedArrow,
    QuestionChoice,
    ProgressBar,
    GridBackground,
    QuizCarousel,
    MobileTutorial,
    QuizOpening,
    TransitionScreen,
    LottieAnimation,
  },
  setup() {
    const questions = require("../misc/questions.json");
    const i18n = useI18n();
    const wentBack = ref(false);
    const questionIndex = ref(0);
    const totalAnsweredQuestions = ref(1);
    const answeredQuestions = ref([]);
    const showIntro = ref(true);
    const showTransition = ref(false);
    const router = useRouter();
    const { currentLang, myType } = useGetters(["currentLang", "myType"]);
    const questionTitle = computed(
      () => questions[questionIndex.value][currentLang.value + "Title"]
    );
    const quizIsCompleted = ref(false);

    const { updateMyType } = useActions({ updateMyType: "updateMyType" });

    const initialSlide = ref(null);

    onMounted(() => {
      setTimeout(() => {
        showIntro.value = false;
      }, 7000);
    });
    const choiceClicked = function (choiceNumber) {
      if (questionIndex.value === 14) {
        answeredQuestions.value[questionIndex.value] = choiceNumber;
        quizIsCompleted.value = true;
        setTimeout(() => {
          validateResults();
        }, 3500);
        return;
      }
      const newQuestion =
        answeredQuestions.value[questionIndex.value] === undefined;
      answeredQuestions.value[questionIndex.value] = choiceNumber;

      setTimeout(() => {
        if (newQuestion) {
          ++totalAnsweredQuestions.value;
        }
        initialSlide.value = null;
        showTransition.value = true;
        answeredQuestions.value[questionIndex.value] = choiceNumber;
        ++questionIndex.value;
        wentBack.value = false;
      }, 500);
    };

    const validateResults = function () {
      const resultArray = [];
      const results = answeredQuestions.value.reduce(
        (acc, cur, index) => {
          const type = questions[index]["choices"][cur]["type"];
          resultArray.push(type);
          ++acc[type.toLowerCase()];
          return acc;
        },
        { e: 0, i: 0, t: 0, f: 0, p: 0, j: 0 }
      );
      const firstLetter = results["e"] > results["i"] ? "e" : "i";
      const secondLetter = results["t"] > results["f"] ? "t" : "f";
      const thirdLetter = results["p"] > results["j"] ? "p" : "j";
      updateMyType(firstLetter + secondLetter + thirdLetter);

      fetch(
        "https://2k0itd0a42.execute-api.ap-northeast-2.amazonaws.com/prod/asiance-eoy-22-save",
        {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Connection: "keep-alive",
            "Cache-Control": "no-cache",
            "access-control-allow-origin": "*",
            Origin: location.origin,
          },
          body: JSON.stringify({
            q: resultArray.join(","),
            result:
              firstLetter.toUpperCase() +
              secondLetter.toUpperCase() +
              thirdLetter.toUpperCase(),
          }),
          mode: "no-cors",
        }
      ).then(() => {
        router.push({
          path: `result/${
            resultsJson[0][currentLang.value].allResults[myType.value].path
          }`,
        });
      });
    };

    const previousQuestion = function () {
      if (questionIndex.value === 0) {
        return;
      }
      --questionIndex.value;
      initialSlide.value = answeredQuestions.value[questionIndex.value];
      wentBack.value = true;
    };

    const nextQuestion = function () {
      if (!questionAlreadyAnswered.value) {
        return;
      }
      wentBack.value = false;
      ++questionIndex.value;
      initialSlide.value = answeredQuestions.value[questionIndex.value];
    };

    const bindQuestionChoice = function (choiceNumber) {
      let selected = null;
      if (answeredQuestions.value[questionIndex.value] !== undefined) {
        selected =
          answeredQuestions.value[questionIndex.value] === choiceNumber;
      }
      return {
        title:
          questions[questionIndex.value]["choices"][choiceNumber][
            currentLang.value
          ],
        type: questions[questionIndex.value]["choices"][choiceNumber]["type"],
        startTime:
          questions[questionIndex.value]["choices"][choiceNumber]["startTime"],
        endTime:
          questions[questionIndex.value]["choices"][choiceNumber]["endTime"],
        questionNumber: questionIndex.value + 1,
        selected: selected,
      };
    };
    const hoveredChoice = ref(null);
    const choiceIsHovered = function (index) {
      hoveredChoice.value = index;
    };
    const firstChoiceIsHovered = computed(() => {
      return hoveredChoice.value === 0;
    });
    const secondChoiceIsHovered = computed(() => {
      return hoveredChoice.value === 1;
    });
    const resetHover = function () {
      hoveredChoice.value = null;
    };

    const questionAlreadyAnswered = computed(() => {
      return answeredQuestions.value[questionIndex.value] !== undefined;
    });

    return {
      questions,
      questionIndex,
      questionTitle,
      wentBack,
      i18n,
      choiceClicked,
      previousQuestion,
      answeredQuestions,
      bindQuestionChoice,
      nextQuestion,
      firstChoiceIsHovered,
      secondChoiceIsHovered,
      choiceIsHovered,
      resetHover,
      questionAlreadyAnswered,
      quizIsCompleted,
      showIntro,
      totalAnsweredQuestions,
      showTransition,
      initialSlide,
      currentLang,
    };
  },
};
</script>
<style lang="scss">
.transition-page {
  transition: all 600ms ease-in-out;
  align-items: center;
}
.quiz-carousel {
  display: flex;
  height: 100%;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}
.quiz__arrow {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
.quiz {
  height: 100%;
  @include media-breakpoint-down(sm) {
    font-size: 16px;
    cursor: url("@/assets/cursor.png"), pointer;
  }
  @include media-breakpoint-up(xl) {
    &__choice--hover {
      transform: scale(0.8);
    }
  }
  &__header {
    position: relative;
    padding: 20px 10px;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
  }
  &__choices {
    display: flex;
    height: 100%;
    @include media-breakpoint-down(lg) {
      gap: 5% !important;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  &__header:before {
    border-top-width: 2px;
    border-top-style: solid;
    border-image: linear-gradient(to right, white, rgba(0, 0, 0, 0)) 5 7 100%;
    width: 70%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
  }
  &__header:after {
    border-top-width: 2px;
    border-top-style: solid;
    border-image: linear-gradient(to left, white, rgba(0, 0, 0, 0)) 5 7 100%;
    width: 70%;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &__arrow-container {
    flex-basis: 10%;
    flex-shrink: 0;
    flex-grow: 0;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  &__title {
    text-align: center;
    flex-basis: 60%;
    padding: 0 10px;
    @include media-breakpoint-down(lg) {
      font-size: 18px;
    }
    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
    }
  }
  &__progress {
    width: 100%;
    padding: 0 50px;
    margin: 0 0 25px 0;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.versus {
  color: $white;
  transform: translateY(get-vw(200px));
  @include media-breakpoint-down(xl) {
    transform: translateY(get-vw(300px));
  }
  @include media-breakpoint-down(md) {
    transform: translateY(120px);
  }
}

.swipe-icon {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  z-index: 990;
  width: 70%;
  pointer-events: none;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}
</style>
