<template>
  <ARow
    class="a-modal-container"
    justify-center
    align-center
    @click.self="$emit('closeModal')"
  >
    <ACol align-center class="a-modal">
      <ARow justify-end style="width: 100%; margin-bottom: 50px">
        <AButton
          icon="cross.png"
          width="40px"
          height="40px"
          icon-width="20px"
          icon-height="20px"
          @click="$emit('closeModal')"
        />
      </ARow>
      <ACol justify-center align-center style="margin: 0 100px">
        <AText
          color="black"
          size="1.4em"
          style="margin-bottom: 20px; text-align: center"
        >
          {{ modalTitle }}
        </AText>
        <AText light color="black" size="1.2em" style="text-align: center">
          <!-- share description text to display inside the modal -->
          <slot></slot>
        </AText>
        <ARow justify-center gap="0 10px" style="margin-top: 50px">
          <AButton
            icon="kk-icn.svg"
            height="55px"
            width="55px"
            icon-height="100%"
            @click="shareKakao"
          />
          <AButton
            icon="fb-icn.svg"
            height="55px"
            width="55px"
            icon-height="100%"
            style="padding-bottom: 0"
            icon-end
            @click="shareFacebook"
          />
          <AButton
            icon="linkedin-icn.svg"
            height="55px"
            width="55px"
            icon-height="100%"
            @click="shareLinkedin"
          />

          <ATooltip text="link copied">
            <template v-slot:activator="{ onClick }">
              <AButton
                icon="copy-link-icn.svg"
                height="55px"
                width="55px"
                icon-height="100%"
                @click="
                  () => {
                    copyToClipBoard();
                    onClick();
                  }
                "
              />
            </template>
          </ATooltip>
        </ARow>
      </ACol>
    </ACol>
  </ARow>
</template>

<script>
import AButton from "./AButton.vue";
import AText from "./AText.vue";
import ACol from "./Flex/ACol.vue";
import ARow from "./Flex/ARow.vue";
import ATooltip from "./ATooltip.vue";

export default {
  name: "share-modal",
  components: { ACol, ARow, AText, AButton, ATooltip },
  props: {
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    urlToShare: {
      type: String,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    kakaoShareInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const copyToClipBoard = function () {
      navigator.clipboard.writeText(props.urlToShare);
    };

    const shareFacebook = function () {
      window
        .open(
          `https://www.facebook.com/dialog/share?app_id=5780885685351911&display=popup&href=${props.urlToShare}/&redirect_uri=${props.urlToShare}/`,
          // "https://www.facebook.com/dialog/share?app_id=5780885685351911&display=popup&href=${props.urlToShare}/&redirect_uri=https://eoy.2022.asiance-dev-02.com/",
          "_blank",
          "width=580,height=400,scrollbars=yes"
        )
        .focus();
    };

    const shareLinkedin = function () {
      window
        .open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${props.urlToShare}/`,
          "_blank",
          "width=600,height=560,scrollbars=yes"
        )
        .focus();
    };

    const shareKakao = function () {
      window.Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: props.kakaoShareInfo.title,
          description: props.kakaoShareInfo.description,
          imageUrl: props.kakaoShareInfo.imageUrl,
          link: {
            mobileWebUrl: props.urlToShare,
            webUrl: props.urlToShare,
          },
        },
      });
    };

    return {
      copyToClipBoard,
      shareFacebook,
      shareLinkedin,
      shareKakao,
    };
  },
};
</script>

<style lang="scss">
.a-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($color: $navy, $alpha: 0.8);
  z-index: 99;
}
.a-modal {
  background: $white;
  padding: 10px 20px 70px 20px;
  max-width: 90vw;
  width: get-vw(700px);
  &--hidden {
    display: none;
  }
  @include media-breakpoint-down(md) {
    width: 90vw;
  }
}
</style>
