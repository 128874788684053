<template>
  <ACol justify-center align-center class="quiz-opening">
    <AText computerFont size="48px" class="title">{{
      $t("intro_text1")
    }}</AText>
  </ACol>
</template>
<script>
import { onMounted } from "vue";
import AText from "@/components/AText.vue";
import ACol from "@/components/Flex/ACol.vue";

export default {
  name: "QuizOpening",
  components: {
    AText,
    ACol,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      window.TypeHangul.type(".title", { intervalType: 30 });
      setTimeout(() => {
        window.TypeHangul.type(".title", {
          intervalType: 30,
          text: props.text,
        });
      }, 2500);
    });
  },
};
</script>
<style lang="scss" scoped>
.quiz-opening {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: $navyFaded;
  transform-origin: bottom center;
  // animation: fadeOut 0.25s ease-in-out 6s forwards;

  .title {
    padding: 0 40px;
    font-size: get-vw(48px);
    &:after {
      content: "";
      width: 2px;
      height: 55%;
      background-color: $white;
      display: inline-block;
      animation: blink 1s infinite step-end;

      @include media-breakpoint-down(sm) {
        height: 20px;
      }
    }
    @include media-breakpoint-down(lg) {
      font-size: 28px;
    }
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
