<template>
  <a-row
    :class="getClass()"
    @click="click"
    :style="cssVars"
    justify-center
    align-center
  >
    <div v-if="text !== ''">
      <a-text :style="textVars" class="btn-text">{{ text }}</a-text>
    </div>
    <div v-if="text !== '' && icon !== ''" class="a-button__spacer" />
    <img
      :class="getIconClass()"
      :style="iconCssVars"
      v-if="icon !== ''"
      :src="getIconUrl()"
    />
  </a-row>
</template>
<script>
import { computed } from "vue";
import ARow from "./Flex/ARow.vue";
import AText from "./AText.vue";

export default {
  name: "a-button",
  props: {
    text: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    click: {
      type: Function,
      required: false,
      default: () => {},
    },
    width: {
      type: String,
      required: false,
      default: "auto",
    },
    height: {
      type: String,
      required: false,
      default: "auto",
    },
    iconWidth: {
      type: String,
      required: false,
      default: "auto",
    },
    iconHeight: {
      type: String,
      required: false,
      default: "auto",
    },
    background: {
      type: String,
      required: false,
      default: "transparent",
    },
    iconEnd: {
      type: Boolean,
      required: false,
      default: false,
    },
    noBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: "",
    },
    presetStyle: {
      type: String,
      required: false,
    },
    customStyle: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const getIconUrl = function () {
      return require("@/assets/icons/" + props.icon);
    };
    const cssVars = computed(() => {
      if (props.presetStyle) {
        return {};
      }

      return {
        width: props.width,
        height: props.height,
        background: props.background,
      };
    });
    const iconCssVars = computed(() => {
      return {
        width: props.iconWidth,
        height: props.iconHeight,
      };
    });
    const textVars = computed(() => {
      let vars = {};
      if (props.color !== "") vars.color = props.color;

      if (props.presetStyle) {
        vars = {};
      }
      return vars;
    });
    const getClass = function () {
      const classes = ["a-button"];
      if (props.icon !== "") {
        classes.push("a-button--no-border");
      }
      if (props.noBorder) {
        classes.push("a-button--no-border");
      }
      if (props.text === "") {
        classes.push("a-button--round");
        classes.push("a-button--no-padding");
      }
      if (props.presetStyle === "navy") {
        classes.push("a-button--navy");
      }
      if (props.presetStyle === "whiteborder") {
        classes.push("a-button--white-border");
      }
      if (props.presetStyle === "whitebg") {
        classes.push("a-button--white-bg");
      }
      return classes.join(" ");
    };
    const getIconClass = function () {
      const classes = ["a-button__icon"];
      if (props.iconEnd) {
        classes.push("a-button__icon--end");
      }
      return classes.join(" ");
    };

    return {
      getIconUrl,
      getClass,
      getIconClass,
      cssVars,
      iconCssVars,
      textVars,
    };
  },
  components: { ARow, AText },
};
</script>

<style lang="scss">
.a-button {
  cursor: url("@/assets/cursor.png"), pointer;
  border: 1px solid;
  padding: 10px 20px;
  &--no-border {
    border: none;
  }
  &--round {
    border-radius: 50%;
  }
  &__icon--end {
    align-self: end;
  }
  &--no-padding {
    padding: 0;
  }
  &__spacer {
    width: 20px;
  }
  &--navy {
    border-color: $navy;
    background-color: $navy;
    color: white;
    width: 220px;
    height: 60px;
    font-size: 20px;
    max-width: 46vw;
    letter-spacing: 0.05em;
    padding: 0;

    .a-text {
      letter-spacing: 0.05em;
    }

    .btn-text {
      color: white;
      font-size: 20px;
    }

    @include media-breakpoint-down(sm) {
      height: 44px;
      width: 158px;
      padding: 0;

      .btn-text {
        font-size: 14px;
      }
    }
  }
  &--white-border {
    border-color: white;
    background-color: none;
    width: 220px;
    height: 60px;
    color: white;
    font-size: 20px;
    max-width: 46vw;

    .btn-text {
      color: white;
      font-size: 20px;
    }

    @include media-breakpoint-down(sm) {
      height: 44px;
      width: 158px;
      padding: 0;

      .btn-text {
        font-size: 14px;
      }
    }
  }

  &--white-bg {
    border-color: white;
    background-color: white;
    width: 220px;
    height: 60px;
    color: $navy;
    font-size: 20px;
    max-width: 46vw;
    padding: 0;

    .a-text {
      letter-spacing: 0.05em;
    }

    .btn-text {
      color: $navy;
      font-size: 20px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      height: 44px;
      width: 158px;

      .btn-text {
        font-size: 14px;
      }
    }
  }
}
</style>
