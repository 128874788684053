<template>
  <ARow>
    <ACol class="progress-col" align-center>
      <img src="@/assets/progress-cursor.gif" class="progress-cursor" />
      <div class="progress-cursor__arrow" />
    </ACol>
  </ARow>
</template>
<script>
import ACol from '../Flex/ACol.vue';
import ARow from '../Flex/ARow.vue';

export default {
  name: 'progress-cursor',
  components: { ACol, ARow },
};
</script>
<style lang="scss">
.progress-cursor {
  width: 8vw;
  height: 8vw;
  max-width: 60px;
  max-height: 60px;
  background: white;
  border-radius: 50%;
  border: 6px solid $red;
  @include media-breakpoint-down(md) {
    border-width: 4px;
  }
  @include media-breakpoint-down(sm) {
    border-width: 2px;
  }
  @include media-breakpoint-down(xs) {
    border-width: 1px;
  }
  &__arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid $red;
    margin-top: -2px;
    @include media-breakpoint-down(sm) {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 10px solid $red;
    }
  }
  .progress-col {
    width: auto;
  }
}
</style>
