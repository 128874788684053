<template>
  <div
    :class="[
      transition ? 'animate' : '',
      'home__container',
      transitionToQuiz ? 'active-transition' : '',
    ]"
  >
    <a-col space-between class="home">
      <div class="clouds">
        <img src="@/assets/cloud-left.svg" class="clouds__left" alt="" />
        <img src="@/assets/cloud-right.svg" class="clouds__right" alt="" />
      </div>
      <div class="bg-landscape">
        <img src="@/assets/city.svg" alt="" class="city" />
        <img src="@/assets/mountain3.svg" alt="" class="mountains" />
      </div>
      <a-col align-center class="home__text--container">
        <a-row>
          <a-text
            class="home__title"
            color="#2a3642"
            uppercase
            secondary
            size="100px"
            >{{ $t("title1")
            }}<a-text uppercase size="100px">{{ $t("title2") }}</a-text>
          </a-text>
        </a-row>
        <a-row class="home__subtitle">
          <a-text uppercase class="home__subtitle-text" size="60px">{{
            $t("subtitle")
          }}</a-text></a-row
        >
        <div class="startbutton" @click="goToQuiz">
          {{ miscText.startBtn }}
        </div>
      </a-col>
    </a-col>
    <div class="paper">
      <div class="top"></div>
      <div class="bottom"></div>
      <div class="mask">
        <img src="@/assets/rolled-paper.png" class="rolled" alt="paper" />
      </div>
    </div>
    <div class="ground-wrap">
      <GroundGrid />
    </div>
  </div>
  <div v-if="isLoading" class="loading-screen">
    <loaderAnimation />
  </div>
</template>

<script>
import loaderAnimation from "@/components/Loader.vue";
import AText from "@/components/AText.vue";
import ACol from "@/components/Flex/ACol.vue";
import ARow from "@/components/Flex/ARow.vue";
import GroundGrid from "@/components/Quiz/GroundGrid.vue";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useSeoMeta } from "@vueuse/head";
import { useGetters, useActions } from "vuex-composition-helpers";
export default {
  name: "HomeView",
  components: { AText, ACol, ARow, loaderAnimation, GroundGrid },
  data() {
    return {
      isLoading: true,
      transition: false,
    };
  },
  setup() {
    const { ogTags, currentLang, miscText } = useGetters([
      "ogTags",
      "currentLang",
      "miscText",
    ]);
    const { setLocale } = useActions(["setLocale"]);

    const router = useRouter();

    const transitionToQuiz = ref(false);
    const i18n = useI18n();

    onMounted(() => {
      setLocale(i18n.locale.value);

      if (currentLang.value === "kr" && window.location.pathname.length <= 1) {
        window.history.pushState({}, null, `${window.location.pathname}kr`);
      }
      if (currentLang.value === "en" && window.location.pathname.length <= 1) {
        window.history.pushState({}, null, `${window.location.pathname}en`);
      }
    });

    useSeoMeta({
      ogUrl: () => ogTags.value.homePage.ogUrl,
      ogType: () => ogTags.value.homePage.ogType,
      ogDescription: () => ogTags.value.homePage.ogDescription,
      ogTitle: () => ogTags.value.homePage.ogTitle,
      ogImage: () =>
        require(`@/assets/share-imgs/share-result-website-${currentLang.value}.png`),
    });

    const goToQuiz = function () {
      i18n.locale.value = currentLang.value;
      transitionToQuiz.value = true;

      setTimeout(() => {
        router.push({ path: currentLang.value + "/quiz" });
      }, 1500);
    };

    return {
      transitionToQuiz,
      goToQuiz,
      miscText,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);

    setTimeout(() => {
      this.transition = true;
    }, 2000);
  },
};
</script>
<style lang="scss">
.home__container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .ground-wrap {
    .ground {
      position: absolute;
      bottom: -35%;
    }
  }
}
.loading-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $red;
  svg {
    width: 216px;
    height: 216px;
    @include media-breakpoint-down(md) {
      width: 100px;
      height: 100px;
    }
  }
}
.home {
  flex-grow: 1;

  .clouds {
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: get-vw(236px);

    &__left {
      opacity: 0;
      width: get-vw(340px);
    }

    &__right {
      opacity: 0;
      margin-top: get-vw(100px);
      width: get-vw(340px);
    }

    @include media-breakpoint-down(md) {
      margin-top: 0;

      &__left {
        margin-top: get-vh-mo(100px);
        width: get-vw-mo(90px);
      }

      &__right {
        margin-top: get-vh-mo(270px);
        width: get-vw-mo(90px);
      }
    }
  }

  .bg-landscape {
    position: absolute;
    bottom: 0;
    display: inherit;
    height: 100vh;
    width: 100%;
    background: linear-gradient(
      180deg,
      #8ab2d3 16.54%,
      #d3a6b8 54.83%,
      #ed7e8c 81.34%,
      rgba(228, 56, 82, 0.6) 100%
    );

    .city {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      width: 100%;
      z-index: 9;
      transform-origin: bottom;
    }

    .mountains {
      transform: translateY(100%);
      transform-origin: bottom;
      position: absolute;
      bottom: 0;
      width: 100%;
      mix-blend-mode: overlay;
      opacity: 0.5;
    }

    .city,
    .mountains {
      @include media-breakpoint-down(md) {
        scale: 3.5;
      }
    }
  }

  &__text--container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    padding: 0 20px;

    @include media-breakpoint-down(md) {
      width: 90%;
    }
  }
  &__bottom--container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }
  &__lang {
    cursor: url("@/assets/cursor.png"), pointer;
    width: 50px;
    > div {
      padding: 10px;
      display: inline-block;
    }
  }
  &__lang--korean {
    text-align: right;
  }
  &__lang--english {
    text-align: left;
  }

  &__title,
  &__title > .a-text {
    font-size: get-vw(80px);
    @include media-breakpoint-down(lg) {
      font-size: 44px;
    }
    @include media-breakpoint-down(md) {
      font-size: 36px;
    }
  }

  &__title > .a-text {
    color: $navy;
  }

  &__subtitle {
    color: $navy;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    position: relative;
  }
  &__subtitle-text {
    color: $navy;
    font-weight: 600;
    font-size: get-vw(36px);
    margin: 10px 0px;
    letter-spacing: 0.05em;
    @include media-breakpoint-down(lg) {
      font-size: 26px;
    }
    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
  }
  .startbutton {
    background: #e43852;
    width: 220px;
    height: 60px;
    border: none;
    margin-top: 40px;
    position: absolute;
    top: 100%;
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease 2.5s;
    cursor: url("@/assets/cursor.png"), pointer;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    &:hover {
      transform: scale(1.2);
    }
  }
}
.paper {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;
  .top,
  .bottom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 1s ease-in;
  }
  .top {
    background: url(@/assets/paper-top.png) no-repeat center center;
    background-size: cover;
  }
  .bottom {
    background: url(@/assets/paper-bottom.png) no-repeat center center;
    background-size: cover;
  }
  .mask {
    background-color: $red;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 70%;
    top: 45%;
    left: 0;
    transform: translateY(-50%);
    transition: transform 1.5s ease;
    .rolled {
      width: auto;
      height: 100%;
      position: absolute;
      top: 0;
      left: calc(100% - 5px);
    }
  }
}
.animate {
  .mask {
    transform: translate(-150%, -50%);
    @include media-breakpoint-down(lg) {
      transform: translate(-200%, -50%);
    }
  }
  .top {
    animation: slideUp 1s linear 1.5s forwards;
  }
  .bottom {
    animation: slideDown 1s linear 1.5s forwards;
  }
  .home {
    &__bottom--container {
      animation: asiancerAppear 0.6s ease-out 2s forwards;
    }
    &__text--container {
      z-index: 99;
      animation: textUp 0.5s ease-in-out 2.1s forwards;
      @include media-breakpoint-down(lg) {
        animation: textUpMobile 0.5s ease-in-out 2.1s forwards;
      }
      .changecolor {
        transition: color 200ms ease-out;
        transition-delay: 2s;
        color: $red;
      }
    }
  }
  .startbutton {
    opacity: 1;
  }
  .clouds {
    &__left {
      animation: slideLeftToRight 0.7s ease-out 2.3s forwards;
    }

    &__right {
      animation: slideRightToLeft 0.7s ease-out 2.3s forwards;
    }
  }
  .bg-landscape {
    .city,
    .mountains {
      animation: cityAppear 0.7s ease-out 2.1s forwards;
    }

    .city,
    .mountains {
      scale: 1.3;
      @include media-breakpoint-down(md) {
        scale: 3.5;
      }
    }
  }

  &.active-transition {
    height: 65%;
    transition: all 1s ease;

    .clouds {
      opacity: 0.5;
      margin-top: get-vw(180px);
      transition: all 1s ease;

      &__right {
        transition: all 1s ease;
        margin-top: 0;
      }
    }

    .bg-landscape {
      .city,
      .mountains {
        scale: 1;
        transition: all 1s ease;
        @include media-breakpoint-down(md) {
          scale: 3.5;
        }
      }
    }

    .home__text--container {
      transition: all 1s ease;
      opacity: 0;
      display: none;
    }

    .startbutton {
      opacity: 0;
    }

    .ground-wrap {
      .ground {
        transition: all 1s ease;
        position: fixed;
        bottom: 0;
      }
    }
  }

  &.active-transition {
    height: 65%;
    transition: all 1s ease;

    .clouds {
      opacity: 0.5;
      margin-top: get-vw(180px);
      transition: all 1s ease;

      &__right {
        margin-top: 0;
      }
    }

    .bg-landscape {
      scale: 1;
      transition: all 1s ease;
    }

    .home__text--container {
      transition: all 1s ease;
      opacity: 0;
      display: none;
    }

    .startbutton {
      transition: all 1s ease;
      opacity: 0;
      display: none;
    }

    .ground-wrap {
      .ground {
        transition: all 1s ease;
        position: fixed;
        bottom: 0;
      }
    }
  }
}

@keyframes slideLeftToRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
    opacity: 0.5;
  }
}

@keyframes slideRightToLeft {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0%);
    opacity: 0.5;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}
@keyframes cityAppear {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 1px);
  }
}

@keyframes asiancerAppear {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0%);
  }
}
@keyframes textUp {
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, -100%);
  }
}
@keyframes textUpMobile {
  0% {
    transform: translate(-50%, -50%);
  }

  100% {
    transform: translate(-50%, -100%);
  }
}
</style>
