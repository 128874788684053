<template>
  <div :class="getClass()" :style="cssVars">
    <slot />
  </div>
</template>
<script>
import { computed } from "vue";

export default {
  name: "a-text",
  props: {
    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },
    red: {
      type: Boolean,
      required: false,
      default: false,
    },
    light: {
      type: Boolean,
      required: false,
      default: false,
    },
    underline: {
      type: Boolean,
      required: false,
      default: false,
    },
    uppercase: {
      type: Boolean,
      required: false,
      default: false,
    },
    computerFont: {
      type: Boolean,
      required: false,
      default: false,
    },
    letterspacing: {
      type: String,
      required: false,
      default: "normal",
    },
    size: {
      type: String,
      required: false,
      default: "12px",
    },
    color: {
      type: String,
      required: false,
      default: "white",
    },
  },
  setup(props) {
    const getClass = function () {
      const classes = ["a-text"];
      if (props.secondary) classes.push("a-text--secondary");
      if (props.red) classes.push("a-text--red");
      if (props.light) classes.push("a-text--light");
      if (props.underline) classes.push("a-text--underline");
      if (props.uppercase) classes.push("a-text--uppercase");
      if (props.computerFont) classes.push("a-text--computer-font");
      return classes.join(" ");
    };

    const cssVars = computed(() => {
      return {
        "--text-size": props.size,
        "--text-color": props.color,
        "--text-letterspacing": props.letterspacing,
      };
    });

    return { getClass, cssVars };
  },
};
</script>
<style lang="scss">
.a-text {
  font-family: "futura-pt";
  font-weight: 700;
  color: var(--text-color);
  font-size: var(--text-size);
  letter-spacing: var(--text-letterspacing);
  line-height: 1.3;
  -webkit-text-stroke: 0px;
  text-transform: none;
  word-break: keep-all;
  &--red {
    color: $red;
    -webkit-text-stroke: 0px;
  }
  &--secondary {
    color: transparent;
    -webkit-text-stroke: 2px var(--text-color);
    @include media-breakpoint-down(md) {
      -webkit-text-stroke: 1px var(--text-color);
    }
  }
  &--light {
    font-weight: 400;
  }
  &--underline {
    &:after {
      content: "";
      width: 100%;
      display: block;
      border-bottom: 2px solid;
    }
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--computer-font {
    font-family: "y-computer";
    font-weight: 400;
  }
}
</style>
