<template>
  <ARow space-around align-center>
    <ACol class="results-page__btn-wrap">
      <ARow class="btn-wrap">
        <a href="https://www.asiance.com/en/" target="_blank" class="nav_link">
          <AButton presetStyle="whitebg" :text="miscText.ctaBtn" />
        </a>
        <AButton
          v-if="myType !== ''"
          presetStyle="navy"
          :text="
            myType === selectedType
              ? miscText.shareResultBtn
              : miscText.shareTypeBtn
          "
          class="share-results-btn"
          @click="shareBtnClicked"
        />
        <a v-else :href="getQuizUrl" target="_blank" class="nav_link">
          <AButton
            presetStyle="navy"
            :text="miscText.takeTestBtn"
            class="share-results-btn"
          />
        </a>
      </ARow>
      <ACol class="link-wrap" v-if="myType !== ''">
        <router-link to="/" class="styled-link">{{
          miscText.testRedoBtn
        }}</router-link>
      </ACol>
      <ACol class="link-wrap" v-else>
        <div class="styled-link" @click="shareBtnClicked">
          {{ miscText.shareTypeBtn }}
        </div>
      </ACol>
    </ACol>
  </ARow>
</template>

<script>
import ACol from "@/components/Flex/ACol.vue";
import ARow from "@/components/Flex/ARow.vue";
import AButton from "@/components/AButton.vue";
import { useGetters } from "vuex-composition-helpers";
import { computed } from "vue";

export default {
  name: "result-view",
  components: {
    ACol,
    ARow,
    AButton,
  },
  setup(_, context) {
    const { myType, selectedType, miscText, currentLang } = useGetters([
      "myType",
      "selectedType",
      "miscText",
      "currentLang",
    ]);

    const shareBtnClicked = () => {
      context.emit("showShareModal");
    };

    const getQuizUrl = computed(() => {
      const currentUrl = window.location.origin;

      let url = "https://eoy.2022.asiance-dev-02.com";

      if (currentUrl.includes(":8080")) return `${url}/${currentLang.value}`;
      return `${window.location.origin}/${currentLang.value}`;
    });

    return {
      myType,
      selectedType,
      miscText,
      shareBtnClicked,
      getQuizUrl,
    };
  },
};
</script>

<style lang="scss">
.results-page {
  a {
    text-decoration: none;
  }
  &__btn-wrap {
    text-transform: uppercase;
    margin-bottom: 60px;

    .btn-wrap {
      gap: 12px;
      margin-bottom: 30px;
    }

    .link-wrap {
      width: fit-content;
      margin: auto;

      .styled-link {
        text-decoration: underline;
        color: white;
        font-weight: 500;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        cursor: url("@/assets/cursor.png"), pointer;
      }
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 40px;
    }
  }

  &__title {
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 48px;
    @include media-breakpoint-down(lg) {
      font-size: 28px;
    }
  }

  .share-results-btn {
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
  }
}
</style>
