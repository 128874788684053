<template>
  <ACol :class="classes" @click="displayed = false">
    <ARow justify-end>
      <AButton
        icon="cross-white.png"
        width="40px"
        height="40px"
        icon-width="20px"
        icon-height="20px"
      />
    </ARow>
    <ACol justify-center style="flex-grow: 1">
      <AText computerFont red size="1.5rem">{{ $t("tutorial") }}</AText>
      <LottieAnimation path="data.json" :height="400" />
    </ACol>
  </ACol>
</template>
<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { computed, ref } from "vue";
import AButton from "../AButton.vue";
import AText from "../AText.vue";
import ACol from "../Flex/ACol.vue";
import ARow from "../Flex/ARow.vue";
export default {
  name: "mobile-tutorial",
  components: { LottieAnimation, AText, ACol, AButton, ARow },
  setup() {
    const displayed = ref(true);
    const classes = computed(() => {
      const classes = ["mobile-tutorial"];
      if (!displayed.value) classes.push("mobile-tutorial--hidden");
      return classes.join(" ");
    });

    return { classes, displayed };
  },
};
</script>
<style lang="scss">
.mobile-tutorial {
  @include media-breakpoint-up(sm) {
    display: none;
  }
  padding: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 999;
  background: #000000;
  opacity: 0.9;
  &--hidden {
    display: none;
  }
}
</style>
