<template>
  <ACol class="results-page">
    <ARow>
      <ACol class="header">
        <Transition mode="out-in" name="fade-in">
          <div>
            <AText class="results-page__title" secondary
              >{{ pageHeader }}
            </AText>
            <AText
              uppercase
              class="results-page__title"
              id="selected-type-name"
            >
              ‘{{ selectedTypeName }}‘
            </AText>
          </div>
        </Transition>
        <ASpacer width="60px" />
      </ACol>
    </ARow>
    <div class="results-view">
      <div class="view-change-arrows" v-if="myType">
        <div
          class="perfect-match-btn left"
          @click="handleSwipe"
          v-if="
            currentView === ResultPageType.PERFECTPARTNER ||
            currentView === ResultPageType.OTHER
          "
        >
          <div class="arrow-wrap">
            <AnimatedArrow color="white" @click="handleSwipe" class="left" />
          </div>
          {{ miscText.myTypebtn }}
        </div>

        <div
          v-if="currentView === ResultPageType.MYRESULT && myType !== ''"
          class="perfect-match-btn right"
          @click="handleSwipe"
        >
          <div class="arrow-wrap">
            <AnimatedArrow
              left="false"
              color="white"
              @click="handleSwipe"
              class="right"
            />
          </div>
          {{ miscText.myPartnerbtn }}
        </div>
      </div>

      <swiper
        :speed="800"
        :allowTouchMove="false"
        :slidesPerView="1"
        :centeredSlides="true"
      >
        <swiper-slide v-if="myType">
          <MyResultsView />
          <ShareMyResults @showShareModal="showModal = true" />
        </swiper-slide>
        <swiper-slide>
          <OtherTypesView @showShareModal="showModal = true" />
          <div class="flex-bottom-wrap">
            <ARow space-around center>
              <SelectableTypes />
            </ARow>
            <ShareMyResults @showShareModal="showModal = true" />
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <Teleport to="body">
      <ShareModal
        :modalTitle="
          myType === selectedType
            ? miscText.shareMyResultTitle
            : miscText.shareTypeTitle
        "
        v-show="showModal"
        @closeModal="showModal = false"
        :urlToShare="getFullUrl"
        :kakaoShareInfo="kakaoShareInfo"
      >
        {{
          myType === selectedType
            ? miscText.shareMyResultDesc
            : miscText.shareTypeDesc
        }}
      </ShareModal>
    </Teleport>
  </ACol>
</template>

<script>
import AText from "@/components/AText.vue";
import ACol from "@/components/Flex/ACol.vue";
import ARow from "@/components/Flex/ARow.vue";
import MyResultsView from "@/components/Results/MyResults.vue";
import OtherTypesView from "@/components/Results/OtherTypes.vue";
import AnimatedArrow from "@/components/Quiz/AnimatedArrow.vue";
import SelectableTypes from "@/components/Results/SelectableTypes.vue";
import { ResultPageType } from "@/utils/types";
import { useGetters, useActions } from "vuex-composition-helpers";
import { computed, watch, onMounted, ref, Teleport } from "vue";
import { useRoute } from "vue-router";
import ShareModal from "@/components/ShareModal.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useSeoMeta, useHead } from "@vueuse/head";
import ShareMyResults from "@/components/Results/ShareMyResults.vue";

export default {
  name: "result-view",
  components: {
    Swiper,
    SwiperSlide,
    AText,
    ACol,
    ARow,
    MyResultsView,
    OtherTypesView,
    Teleport,
    ShareModal,
    AnimatedArrow,
    SelectableTypes,
    ShareMyResults,
  },
  setup() {
    const {
      myType,
      myTypeResult,
      currentView,
      pageHeader,
      selectedType,
      allTypeResults,
      miscText,
      selectedTypeName,
      currentLang,
      ogTags,
    } = useGetters([
      "myType",
      "myTypeResult",
      "allTypeResults",
      "currentView",
      "pageHeader",
      "selectedType",
      "miscText",
      "selectedTypeName",
      "currentLang",
      "ogTags",
    ]);

    const resultsJson = require("@/misc/results.json");

    const showModal = ref(false);

    const { updateSelectedType } = useActions(["updateSelectedType"]);

    const route = useRoute();

    const routeParam = route.params.type;

    const getCurrentResultType = computed(() => {
      return resultsJson[1][routeParam];
    });

    updateSelectedType(getCurrentResultType.value);

    const originPath = computed(() => {
      let origin = window.location.origin;

      if (origin.includes("localhost" || ":80" || "127.0.0.1")) {
        return "https://eoy.2022.asiance-dev-02.com";
      }
      return origin;
    });

    const getFullUrl = computed(() => {
      if (myType.value && myType.value === selectedType.value) {
        return `${originPath.value}/${currentLang.value}/result/${
          allTypeResults.value[myType.value].path
        }`;
      }
      return `${originPath.value}/${currentLang.value}/type/${
        allTypeResults.value[selectedType.value].path
      }`;
    });

    onMounted(() => {
      window.TypeHangul.type("#selected-type-name", {
        intervalType: 15,
        text: `‘${selectedTypeName.value}‘`,
      });
    });

    watch(selectedTypeName, (value) => {
      window.TypeHangul.type("#selected-type-name", {
        intervalType: 15,
        text: `‘${value}‘`,
      });
    });

    watch(currentView, () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });

    const handleSwipe = (e) => {
      const swiper = document.querySelector(".swiper").swiper;
      if (e.target.classList.contains("right")) {
        swiper.slideNext();
        setTimeout(() => {
          updateSelectedType(myTypeResult.value.partner);
        }, 250);
      }
      if (e.target.classList.contains("left")) {
        swiper.slidePrev();
        setTimeout(() => {
          updateSelectedType(myType.value);
        }, 250);
      }
    };

    useHead({
      meta: [
        {
          name: "title",
          content: () => ogTags.value.resultPage.ogTitle,
        },
        {
          name: "description",
          content: () => ogTags.value.resultPage.ogDescription,
        },
      ],
    });

    const kakaoOGImage = computed(() => {
      return myType.value === selectedType.value
        ? require(`@/assets/share-imgs/kakao-${myType.value}.jpg`)
        : require(`@/assets/share-imgs/kakao-${selectedType.value}.jpg`);
    });

    const ogTitle = computed(() => {
      return myType.value === selectedType.value
        ? `${ogTags.value.resultPage.ogTitle} ${myTypeResult.value.name}!`
        : `${ogTags.value.typePage.ogTitle} ${selectedTypeName.value}!`;
    });

    const ogImage = computed(() => {
      return myType.value === selectedType.value
        ? require(`@/assets/share-imgs/share-result-${myType.value}-${currentLang.value}.png`)
        : require(`@/assets/share-imgs/share-type-${selectedType.value}-${currentLang.value}.png`);
    });

    // Define Meta Head Properties
    useSeoMeta({
      description:
        "Find out your work personality by taking this quiz made by Asiance",
      ogUrl: () => getFullUrl.value,
      ogType: () => ogTags.value.resultPage.ogType,
      ogDescription: () => ogTags.value.resultPage.ogDescription,
      ogTitle: () => ogTitle.value,
      ogImage: () => ogImage.value,
    });

    const kakaoShareInfo = computed(() => {
      return {
        title: ogTitle.value,
        description:
          myType.value === selectedType.value
            ? ogTags.value.resultPage.ogDescription
            : ogTags.value.typePage.ogDescription,
        imageUrl: `${originPath.value}${kakaoOGImage.value}`,
      };
    });

    return {
      kakaoOGImage,
      handleSwipe,
      kakaoShareInfo,
      showModal,
      myType,
      myTypeResult,
      currentView,
      pageHeader,
      selectedType,
      ResultPageType,
      allTypeResults,
      miscText,
      selectedTypeName,
      getFullUrl,
      updateSelectedType,
    };
  },
};
</script>

<style lang="scss" scoped>
.results-page {
  background-color: $red;
  color: white;
  position: relative;
  /* min-height: fit-content; */
  flex-basis: 100%;
  @include media-breakpoint-down(lg) {
    height: auto;
  }

  &__title {
    letter-spacing: 0.05em;
  }
}

.nav_link {
  text-decoration: none;
}

.header {
  width: 100vw;
  position: relative;
  padding: 20px;
  background-color: $red;

  &:after {
    border-top-width: 2px;
    border-top-style: solid;
    border-image: linear-gradient(to left, white, rgba(0, 0, 0, 0)) 5 7 100%;
    width: 100%;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.results-view {
  position: relative;

  .view-change-arrows {
    max-width: 1620px;
    width: 90vw;
    position: absolute;
    margin: auto;
    top: 27%;
    left: 0;
    right: 0;
    z-index: 90;

    .perfect-match-btn {
      letter-spacing: 0.05em;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: white;
      font-size: 20px;
      font-weight: bold;
      cursor: url("@/assets/cursor.png"), pointer;
      position: absolute;
      top: 0;
      gap: 8px;

      &.left {
        width: 185px;
        text-align: left;
        left: 0;
      }
      &.right {
        width: 220px;
        text-align: right;
        right: 0;
        flex-direction: row-reverse;
      }
      @include media-breakpoint-down(sm) {
        gap: 2px;
      }
    }

    @include media-breakpoint-down(xxl) {
      top: 30px;
    }

    @include media-breakpoint-down(lg) {
      .perfect-match-btn {
        font-size: 14px;
        &.left,
        &.right {
          width: auto;
        }
      }
    }
  }
}

.flex-bottom-wrap {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
  }
}

.swiper-wrapper {
  height: auto !important;
}
</style>
