export const scrollToTarget = function (duration, target) {
  const top = target.getBoundingClientRect().top;
  const startPos = window.pageYOffset;
  const diff = startPos - top;

  let startTime = null;
  let requestId;

  const currentTime = Date.now();

  const loop = () => {
    if (!startTime) {
      startTime = currentTime;
    }
    // Elapsed time in miliseconds
    const time = Date.now() - startTime;

    const percent = Math.min(time / duration, 1);
    window.scrollTo(0, startPos - diff * percent);

    if (time < duration) {
      // Continue moving
      requestId = window.requestAnimationFrame(loop);
    } else {
      window.cancelAnimationFrame(requestId);
    }
  };
  requestId = window.requestAnimationFrame(loop);
};
