const types = {
  etp: "etp",
  etj: "etj",
  efp: "efp",
  efj: "efj",
  itp: "itp",
  itj: "itj",
  ifp: "ifp",
  ifj: "ifj",
};

const ResultPageType = {
  PERFECTPARTNER: "MyPerfectPartnerView",
  MYRESULT: "MyResultView",
  OTHER: "OtherTypesView",
};

export { types, ResultPageType };
