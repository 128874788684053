<template>
  <ACol>
    <ARow space-around align-center class="result-avatar-wrap">
      <ACol class="avatar-container">
        <img
          src="@/assets/circ-text-congrats.png"
          alt="congrats"
          id="congrats-spinner"
        />
        <img
          :src="getSelectedAvatar"
          alt="my-result-avatar"
          id="my-result-avatar"
        />
        <img src="@/assets/pin.svg" alt="pin" id="pin" />
      </ACol>
    </ARow>
    <ARow space-around align-center>
      <ACol space-around align-center>
        <div class="result-sub">
          <AText computer-font size="1.5rem" class="question-page__title">
            "{{ myTypeResult.subtitle }}"
          </AText>
        </div>
        <div class="result-desc">
          <p>{{ myTypeResult.description }}</p>
        </div>
      </ACol>
    </ARow>
  </ACol>
</template>

<script>
import AText from "@/components/AText.vue";
import ACol from "@/components/Flex/ACol.vue";
import ARow from "@/components/Flex/ARow.vue";
import { computed } from "vue";
import { useGetters, useActions } from "vuex-composition-helpers";

export default {
  name: "my-results-view",
  components: { AText, ACol, ARow },

  setup() {
    const { myType, myTypeResult, currentView, getSelectedAvatar, miscText } =
      useGetters([
        "myType",
        "myTypeResult",
        "currentView",
        "getSelectedAvatar",
        "miscText",
      ]);

    const { updateSelectedType } = useActions(["updateSelectedType"]);

    const getImgUrl = computed((imgsrc) => {
      return require(imgsrc);
    });

    return {
      getSelectedAvatar,
      getImgUrl,
      myType,
      myTypeResult,
      currentView,
      updateSelectedType,
      miscText,
    };
  },
};
</script>

<style lang="scss" scoped>
.result-avatar-wrap {
  margin: 60px 0 40px;
  position: relative;

  .avatar-container {
    animation: bounceIn 1s;
    position: relative;
    width: 360px;

    @include media-breakpoint-down(lg) {
      width: 260px;
    }
  }

  #congrats-spinner {
    width: 100%;
    animation: spin 20s steps(2) 0.2s infinite;
    -webkit-animation: spin 20s linear infinite;
  }

  #pin {
    position: absolute;
    top: 34px;
    left: 0;
    right: 0;
    margin: auto;
    width: 12px;
    height: auto;
  }

  #my-result-avatar {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    box-shadow: rgb(0 0 0 / 30%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
  }

  #perfect-match-btn {
    cursor: url("@/assets/cursor.png"), pointer;
    position: absolute;
    color: white;
    font-size: 20px;
    font-weight: bold;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    text-align: right;
    left: 70%;
    width: 150px;

    .arrow-wrap {
      position: absolute;
      right: -65px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: fit-content;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-top: 90px;

    #perfect-match-btn {
      font-size: 12px;
      right: 80px;
      top: -330px;
      left: auto;
    }
  }
}

.result-sub {
  max-width: 1000px;
  width: 90%;
  word-break: keep-all;

  .a-text {
    letter-spacing: 0.05em;
  }

  @include media-breakpoint-down(sm) {
    width: 70%;
  }
}

.result-desc {
  word-break: keep-all;
  max-width: 1000px;
  width: 90%;
  font-size: 20px;
  margin-bottom: 30px;
  height: fit-content;
  letter-spacing: 0.05em;
}
@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
</style>
