<template>
  <a-flex v-bind="$props" class="a-col">
    <slot />
  </a-flex>
</template>
<script>
import AFlex from "./AFlex.vue";
export default {
  name: "a-col",
  components: { AFlex },
};
</script>
<style lang="scss">
.a-col {
  flex-direction: column;
}
</style>
