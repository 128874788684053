<template>
  <div class="arrow__container">
    <div :class="getClasses(0)"></div>
    <div :class="getClasses(1)"></div>
    <div :class="getClasses(2)"></div>
  </div>
</template>
<script>
export default {
  name: "animated-arrow",
  props: {
    left: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
    },
    activated: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const getClasses = function (index) {
      const classes = ["arrow"];
      if (!props.activated) classes.push("arrow__deactivated");
      if (props.left) {
        classes.push("arrow--left");
      }
      if (index === 0) {
        classes.push(props.left ? "arrow--left-first" : "arrow--first");
      }
      if (index === 1) {
        classes.push(props.left ? "arrow--left-middle" : "arrow--middle");
      }
      if (index === 2) {
        classes.push(props.left ? "arrow--left-last" : "arrow--last");
      }
      if (props.color) {
        classes.push(props.color);
      }
      return classes.join(" ");
    };
    return { getClasses };
  },
};
</script>
<style lang="scss">
.arrow__container {
  cursor: url("@/assets/cursor.png"), pointer;
  display: flex;
  width: 60px;
  height: 20px;
  position: relative;
  @include media-breakpoint-down(sm) {
    width: 30px;
    scale: 0.8;
  }
}
.arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid $red;
  &.white {
    border-right-color: white;
  }

  &--left {
    border-right: none;
    border-left: 10px solid $red;

    &.white {
      border-right: none;
      border-left-color: white;
    }
  }
  &--first {
    opacity: 0.5;
    right: 0;
    animation: slide 1s linear infinite;
    border-left-width: 8px;
    border-top-width: 8px;
    border-bottom-width: 8px;
    top: 2px;
  }
  &--middle {
    opacity: 0.8;
    right: 12px;
    animation: slide2 1s linear infinite;
    border-top-width: 9px;
    border-bottom-width: 9px;
    border-left-width: 9px;
    top: 1px;
  }
  &--last {
    right: 24px;
    animation: slide3 1s linear infinite;
  }
  &--left-first {
    opacity: 0.5;
    left: 0;
    animation: slideReverse 1s linear infinite;
    border-left-width: 8px;
    border-top-width: 8px;
    border-bottom-width: 8px;
    top: 2px;
  }

  &--left-middle {
    opacity: 0.8;
    left: 10px;
    animation: slideReverse2 1s linear infinite;
    border-top-width: 9px;
    border-bottom-width: 9px;
    border-left-width: 9px;
    top: 1px;
  }
  &--left-last {
    left: 21px;
    animation: slideReverse3 1s linear infinite;
  }
  &__deactivated {
    animation: none !important;
    opacity: 0;
  }
}

@keyframes slide {
  0% {
    opacity: 0.2;
    transform: translateX(0px);
  }
  100% {
    opacity: 0.2;
    transform: translateX(-10px);
  }
}
@keyframes slide2 {
  0% {
    opacity: 0.5;
    transform: translateX(0px);
  }
  100% {
    opacity: 0.5;
    transform: translateX(-10px);
  }
}
@keyframes slide3 {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 1;
    transform: translateX(-10px);
  }
}

@keyframes slideReverse {
  0% {
    opacity: 0.2;
    transform: translateX(0px);
  }
  100% {
    opacity: 0.2;
    transform: translateX(10px);
  }
}
@keyframes slideReverse2 {
  0% {
    opacity: 0.5;
    transform: translateX(0px);
  }
  100% {
    opacity: 0.5;
    transform: translateX(10px);
  }
}
@keyframes slideReverse3 {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 1;
    transform: translateX(10px);
  }
}
</style>
