<template>
  <a-col class="question-choice" align-center @click="choiceClicked">
    <div :class="gifContainerClass()">
      <video
        :class="gifClass()"
        ref="videoRef"
        muted
        playsinline
        preload="metadata"
      >
        <source
          src="https://player.vimeo.com/progressive_redirect/playback/785880553/rendition/540p/file.mp4?loc=external&signature=79ef0ac6d6d4d028ec99d78dc53c9238a0ad95a22a65d338620778d7460fffb3"
        />
      </video>
      <loaderAnimation v-if="isLoading" :scale="0.4" red />
    </div>
    <div class="question-choice__title">
      <div :class="getTickClasses()">
        <svg
          width="33"
          height="28"
          viewBox="0 0 33 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="question-choice__tick-image"
        >
          <path
            d="M31.6265 1.57812C30.4676 0.362956 28.5882 0.362956 27.4293 1.57812L10.0295 19.8188L5.56193 15.1298C4.40305 13.9147 2.52363 13.9147 1.36476 15.1298C0.205887 16.345 0.205887 18.3165 1.36476 19.5309L7.93333 26.4229C8.49012 27.0068 9.24342 27.3334 10.0302 27.3334C10.8169 27.3334 11.5737 27.0075 12.1305 26.4229L31.6265 5.97918C32.7853 4.76401 32.7853 2.79329 31.6265 1.57812Z"
            fill="#446580"
          />
        </svg>
      </div>
      <img
        :src="questionBackground"
        class="question-choice__title-background"
      />
      <a-text size="18px" uppercase class="question-choice__title-text">
        {{ title }}
      </a-text>
    </div>
  </a-col>
</template>
<script>
import loaderAnimation from "@/components/Loader.vue";
import { ref, onMounted, watch } from "vue";
import ACol from "@/components/Flex/ACol.vue";
import AText from "../AText.vue";
import { computed } from "@vue/reactivity";
export default {
  name: "question-choice",
  components: { ACol, AText, loaderAnimation },
  props: {
    selected: {
      type: [Boolean, null],
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    questionNumber: {
      type: Number,
      required: true,
    },
    startTime: {
      type: Number,
      required: true,
      default: 0,
    },
    endTime: {
      type: Number,
      required: true,
      default: 3,
    },
    firstChoice: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const isLoading = ref(false);
    const videoRef = ref(null);

    const getClasses = function () {
      const classes = ["question-choice"];
      if (props.firstChoice) classes.push("question-choice--first");
    };

    const questionBackground = computed(() => {
      return require(`@/assets/question_${props.firstChoice ? "1" : "2"}.png`);
    });
    const gifClass = function () {
      const classes = ["question-choice__gif"];
      if (props.selected === false) classes.push("question-choice__gif--grey");
      return classes.join(" ");
    };

    const gifContainerClass = function () {
      const classes = ["question-choice__gif-container"];
      if (props.firstChoice)
        classes.push("question-choice__gif-container--first");
      if (props.selected)
        classes.push("question-choice__gif-container--selected");
      return classes.join(" ");
    };

    const getTickClasses = function () {
      const classes = ["question-choice__tick"];
      if (props.firstChoice) classes.push("question-choice__tick--first");
      if (props.selected) classes.push("question-choice__tick--selected");
      return classes.join(" ");
    };

    const choiceClicked = function () {
      context.emit("choice-clicked");
    };

    const playVideo = function (start, end) {
      const startTime = start + 0.3;
      const endTime = end - 0.3;
      if (videoRef.value) videoRef.value.defaultPlaybackRate = 1.0;

      if (startTime <= 4) {
        videoRef.value.currentTime = startTime;
        if (videoRef.value) videoRef.value.playbackRate = 1.0;
      }

      videoRef.value.currentTime = startTime;

      videoRef.value.onloadstart = function () {
        isLoading.value = true;
      };
      videoRef.value.onloadedmetadata = function () {
        isLoading.value = false;
        if (videoRef.value) {
          videoRef.value.defaultPlaybackRate = 1.0;
          videoRef.value.play();
        }
      };
      videoRef.value.ontimeupdate = function () {
        if (videoRef.value)
          if (videoRef.value.currentTime >= endTime) {
            videoRef.value.currentTime = startTime;
            videoRef.value.defaultPlaybackRate = 1.0;
            videoRef.value.playbackRate = 1.0;
            videoRef.value.play();
          }
      };
    };

    onMounted(() => {
      watch(
        () => props.questionNumber,
        (value) => {
          if (value) {
            playVideo(props.startTime, props.endTime);
          }
        }
      );
      playVideo(props.startTime, props.endTime);
    });

    return {
      questionBackground,
      getClasses,
      gifClass,
      getTickClasses,
      choiceClicked,
      gifContainerClass,
      videoRef,
      playVideo,
      isLoading,
    };
  },
};
</script>
<style lang="scss">
.quiz__choices {
  z-index: 10;
}
.question-choice {
  transition: all 300ms ease-in-out;
  cursor: url("@/assets/cursor.png"), pointer;
  z-index: 2;
  @include media-breakpoint-up(md) {
    &:hover {
      transform: scale(1.1);
    }
    &:hover > .question-choice__gif-container {
      transform: rotate(0) translateX(0);
    }
  }
  &__gif-container {
    transition: transform 300ms ease-in-out;
    transform: rotate(8deg) translateX(-1vw);
    z-index: 2;
    border: 7px solid $bluegrey;
    width: 20vmax;
    height: 20vmax;
    overflow: hidden;
    background: $navy;
    @include media-breakpoint-up(xl) {
      max-width: get-vw(400px);
      max-height: get-vw(400px);
    }
    @include media-breakpoint-down(xl) {
      width: 25vw;
      height: 25vw;
    }
    @include media-breakpoint-down(md) {
      width: 35vw;
      height: 35vw;
    }
    @include media-breakpoint-down(sm) {
      width: 234px;
      height: 234px;
    }
  }
  &__gif-container--first {
    border-color: $red;
    transform: rotate(-8deg) translateX(1vw);
  }
  &__gif-container--selected {
    transform: rotate(0) translateX(0);
  }
  &__tick {
    width: 41px;
    height: 41px;
    position: absolute;
    top: 0;
    right: 30px;
    margin-top: -25px;
    z-index: 3;
    border-radius: 50%;
    border: 7px solid $bluegrey;
    background: white;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      opacity: 0.2;
    }
  }
  &__tick--selected {
    width: 50px;
    height: 50px;
    svg {
      opacity: 1;
    }
  }
  &__tick--first {
    border-color: $red;
    left: 30px;
    path {
      fill: $red;
    }
  }
  &__tick-image {
    height: 70%;
  }
  &__gif {
    position: relative;
    top: -4px;
    left: -4px;
    height: calc(100% + 8px);
    width: calc(100% + 8px);
    object-fit: cover;
  }

  &__gif--first {
    border-color: $red;
  }

  &__gif--grey {
    filter: grayscale(100%);
  }
  &__gif--grey:hover {
    filter: initial;
  }
  &__title {
    margin-top: 7px;
    position: relative;
    width: 30vmax;
    @include media-breakpoint-up(xl) {
      max-width: get-vw(400px);
    }
    @include media-breakpoint-down(lg) {
      width: 30vw;
    }
    @include media-breakpoint-down(md) {
      width: 40vw;
    }
    @include media-breakpoint-down(sm) {
      width: 70vw;
    }
  }

  &__title-background {
    width: 100%;
  }
  &__title-text {
    position: absolute;
    top: 47%;
    left: 0;
    right: 0;
    margin: auto;
    width: 70%;
    text-align: center;
    transform: translateY(-50%);
    font-size: get-vw(20px);
    @include media-breakpoint-down(xl) {
      font-size: 14px;
    }
  }
}
</style>
