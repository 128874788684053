<template>
  <div :class="getClass()" :style="cssVars">
    <svg
      width="218"
      height="219"
      viewBox="0 0 218 219"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M143.282 217.918C144.186 216.621 143.476 214.416 141.152 211.628C111.969 175.767 99.8319 144.9 104.803 119.091C92.9883 136.859 90.4058 155.794 96.9266 175.897C103.641 196.065 116.812 210.136 137.149 218.178C140.506 219.345 142.572 219.28 143.282 217.918Z"
        fill="white"
      />
      <path
        d="M100.222 105.668C82.5321 93.8009 63.68 91.207 43.7303 97.7566C23.6515 104.501 9.57701 117.924 1.63588 138.157C0.34464 141.529 0.409194 143.604 1.82956 144.317C3.18536 145.225 5.31592 144.512 8.09209 142.177C43.7303 112.931 74.5264 100.675 100.222 105.668Z"
        fill="white"
      />
      <path
        d="M80.785 1.39661C77.4278 0.0996579 75.3618 0.164505 74.6516 1.59115C73.7478 2.95295 74.4579 5.09291 76.7821 7.88135C105.964 43.6772 118.166 74.6094 113.195 100.419C125.01 82.6505 127.592 63.715 121.007 43.6123C114.357 23.5096 100.928 9.37285 80.785 1.39661Z"
        fill="white"
      />
      <path
        d="M216.364 81.2852C217.655 77.9132 217.591 75.838 216.17 75.1247C214.879 74.2168 212.684 74.9302 209.908 77.2647C174.27 106.576 143.474 118.832 117.778 113.839C135.468 125.706 154.32 128.3 174.27 121.75C194.348 115.006 208.423 101.582 216.364 81.2852Z"
        fill="white"
      />
    </svg>
  </div>
</template>
<script>
import { computed } from 'vue';

export default {
  name: 'loaderAnimation',
  props: {
    red: {
      type: Boolean,
      required: false,
      default: false,
    },
    scale: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  setup(props) {
    const getClass = function () {
      const classes = ['loader'];
      if (props.red) classes.push('loader--red');
      return classes.join(' ');
    };
    const cssVars = computed(() => {
      return { '--scale': props.scale };
    });

    return { getClass, cssVars };
  },
};
</script>
<style lang="scss" scoped>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(var(--scale));
  z-index: 999;
  svg {
    transform-origin: center center;
    animation: rotate 2.5s linear infinite;
  }
  &--red {
    path {
      fill: $red;
    }
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
