import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import QuizView from "../views/QuizView.vue";
import ResultView from "../views/ResultView.vue";
import RouterForwardView from "../views/RouterForwardView.vue";

const routes = [
  {
    path: "/:lang",
    component: RouterForwardView,
    children: [
      {
        path: "",
        name: "locale-home",
        component: HomeView,
      },
      {
        path: "quiz",
        name: "locale-quiz",
        component: QuizView,
      },
      {
        path: "result/:type",
        name: "locale-result",
        component: ResultView,
      },
      {
        path: "type/:type",
        name: "locale-type",
        component: ResultView,
      },
    ],
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/quiz",
    name: "quiz",
    component: QuizView,
  },
  {
    path: "/result/:type",
    name: "result",
    component: ResultView,
  },
  {
    path: "/type/:type",
    name: "type",
    component: ResultView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
