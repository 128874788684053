<template>
  <ACol class="selectable-types-wrap">
    <ARow space-around>Other Types</ARow>
    <ARow class="selectable-types-list">
      <div
        class="selectable-type"
        v-for="{ type } in selectableTypes"
        :key="type"
        @click="onAvatarClick(type)"
      >
        <img
          :src="require(`@/assets/results/${type}_navy.png`)"
          :class="[
            type === selectedType ? 'selected' : 'grey-img',
            'selectable-avatar',
          ]"
        />
        <svg
          v-if="type === selectedType"
          class="selected-circle"
          width="141"
          height="140"
          viewBox="0 0 141 140"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="70" cy="70" r="69.5" stroke="white" />
        </svg>
        <svg
          v-if="type === myPartnerType"
          class="selected-circle"
          width="141"
          height="140"
          viewBox="0 0 141 140"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M123.138 35.4168C123.138 35.4168 136.291 33.798 138.635 25.0489C139.64 21.3959 137.588 17.677 134.082 16.7375C131.606 16.0739 129.045 16.9984 127.431 18.9037C126.986 16.4465 125.23 14.3657 122.754 13.7022C119.248 12.7627 115.611 14.9573 114.63 18.6169C112.299 27.3175 123.138 35.4168 123.138 35.4168Z"
            fill="white"
          />
        </svg>
      </div>
    </ARow>
  </ACol>
</template>

<script>
import ACol from "@/components/Flex/ACol.vue";
import ARow from "@/components/Flex/ARow.vue";
import { useGetters, useActions } from "vuex-composition-helpers";
import { scrollToTarget } from "@/utils/scrollToTarget";

export default {
  name: "my-results-view",
  components: { ACol, ARow },
  setup() {
    const { selectableTypes, myPartnerType, selectedType } = useGetters([
      "selectedType",
      "selectableTypes",
      "myPartnerType",
    ]);

    const { updateSelectedType } = useActions(["updateSelectedType"]);

    const onAvatarClick = (type) => {
      const target = document.querySelector(".nav");
      scrollToTarget(350, target);
      updateSelectedType(type);
    };

    return {
      onAvatarClick,
      selectedType,
      updateSelectedType,
      selectableTypes,
      myPartnerType,
    };
  },
};
</script>

<style lang="scss" scoped>
.selectable-types-wrap {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  max-width: 90%;

  .selectable-types-list {
    margin: 40px auto;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;

    .selectable-type {
      position: relative;
      cursor: url("@/assets/cursor.png"), pointer;

      .selected-circle {
        position: absolute;
        top: -5px;
        bottom: 0;
        left: -12px;
        right: 0;
        margin: auto;
        width: 120%;

        @include media-breakpoint-down(md) {
          top: -4px;
          left: -8px;
        }
      }

      .selectable-avatar {
        width: 100px;

        &.grey-img {
          filter: grayscale(1);
          &:hover {
            filter: grayscale(0);
          }
        }

        &.selected {
          width: 120px;
          border-radius: 50%;
          box-shadow: rgb(0 0 0 / 30%) 0px 19px 38px,
            rgb(0 0 0 / 22%) 0px 15px 12px;
        }

        @include media-breakpoint-down(md) {
          width: 69px;

          &.selected {
            width: 83px;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      gap: 20px;
    }
  }
}
</style>
