<template>
  <router-view />
</template>
<script>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useActions } from "vuex-composition-helpers";

export default {
  name: "router-forward-view",
  setup() {
    const { setLocale } = useActions(["setLocale"]);

    const lang = useRouter().currentRoute.value.params.lang;
    let locale = "en";
    if (lang === "kr") {
      locale = "kr";
    }

    setLocale(lang);

    useI18n().locale.value = locale;
  },
};
</script>
