import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import TypeHangul from "type-hangul";
import { createHead } from "@vueuse/head";

TypeHangul;

const head = createHead();

createApp(App).use(i18n).use(router).use(store).use(head).mount("#app");
