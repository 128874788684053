<template>
  <div class="a-tooltip__container" ref="containerRef">
    <slot name="activator" @click="displayTooltip()" />
    <div class="a-tooltip__arrow" v-if="displayed"></div>
    <div id="tooltip" v-if="displayed" class="a-tooltip">
      <a-text color="white">{{ text }}</a-text>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import AText from "./AText.vue";
export default {
  name: "a-tooltip",
  props: {
    text: {
      type: String,
      required: false,
      default: "",
    },
    timeout: {
      type: Number,
      required: false,
      default: 900,
    },
  },
  components: { AText },
  setup(props) {
    let displayed = ref(false);
    const containerRef = ref(null);
    const displayTooltip = function () {
      displayed.value = true;
      setTimeout(() => {
        displayed.value = false;
      }, props.timeout);
    };
    return {
      displayed,
      displayTooltip,
      containerRef,
    };
  },
};
</script>
<style lang="scss">
.a-tooltip {
  background: #6a737b;
  position: absolute;
  padding: 12px 22px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  margin-top: 10px;
  white-space: nowrap;
  &__container {
    position: relative;
  }
  &__arrow {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #6a737b;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
