<template>
  <ACol justify-center class="transition-screen">
    <AText computerFont size="48px" class="transition-screen__text">
      {{ text }}
    </AText>
  </ACol>
</template>
<script>
import AText from "../AText.vue";
import ACol from "../Flex/ACol.vue";
import { onMounted } from "vue";

export default {
  name: "TransitionScreen",
  components: { ACol, AText },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    onMounted(() => {
      const target = document.querySelector(".transition-screen__text");
      target.addEventListener("th.endType", function () {
        setTimeout(() => {
          context.emit("finished-typing");
        }, 800);
      });
      window.TypeHangul.type(".transition-screen__text", {
        intervalType: 22,
        text: props.text,
      });
    });
  },
};
</script>
<style lang="scss">
.transition-screen {
  position: absolute;
  z-index: 11;
  width: 100%;
  height: 100%;
  background-color: $navyFaded;
  padding: 50px;

  &__text {
    opacity: 1;
    width: 70%;
    font-size: get-vw(48px);
    @include media-breakpoint-down(lg) {
      font-size: 28px;
    }
    @include media-breakpoint-down(sm) {
      width: 80%;
    }
    &:after {
      content: "";
      width: 2px;
      height: 55%;
      background-color: $white;
      display: inline-block;
      animation: blink 1s infinite step-end;
      @include media-breakpoint-down(sm) {
        height: 14px;
      }
    }
  }
  @keyframes blink {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
</style>
