<template>
  <div :class="getClass()" :style="cssVars">
    <slot />
  </div>
</template>
<script>
import { computed } from "@vue/reactivity";

export default {
  name: "a-flex",
  props: {
    alignCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
    alignStart: {
      type: Boolean,
      required: false,
      default: false,
    },
    alignEnd: {
      type: Boolean,
      required: false,
      default: false,
    },
    justifyCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
    justifyStart: {
      type: Boolean,
      required: false,
      default: false,
    },
    justifyEnd: {
      type: Boolean,
      required: false,
      default: false,
    },
    spaceBetween: {
      type: Boolean,
      required: false,
      default: false,
    },
    spaceAround: {
      type: Boolean,
      required: false,
      default: false,
    },
    gap: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const getClass = function () {
      const classes = ["a-flex"];
      if (props.alignCenter) classes.push("a-flex--align-center");
      if (props.alignStart) classes.push("a-flex--align-start");
      if (props.alignEnd) classes.push("a-flex--align-end");
      if (props.justifyCenter) classes.push("a-flex--justify-center");
      if (props.justifyStart) classes.push("a-flex--justify-start");
      if (props.justifyEnd) classes.push("a-flex--justify-end");
      if (props.spaceBetween) classes.push("a-flex--space-between");
      if (props.spaceAround) classes.push("a-flex--space-around");
      return classes.join(" ");
    };
    const cssVars = computed(() => {
      return {
        gap: props.gap,
      };
    });
    return { getClass, cssVars };
  },
};
</script>
<style lang="scss">
.a-flex {
  display: flex;
  &--align-center {
    align-items: center;
  }
  &--align-start {
    align-items: "start";
  }
  &--align-end {
    align-items: end;
  }
  &--justify-center {
    justify-content: center;
  }
  &--justify-start {
    justify-content: "start";
  }
  &--justify-end {
    justify-content: end;
  }
  &--space-between {
    justify-content: space-between;
  }
  &--space-around {
    justify-content: space-around;
  }
}
</style>
