<template>
  <div class="sky" :class="completed ? 'sky--completed' : ''" :style="skyColor">
    <div
      class="sun"
      :class="completed ? 'sun--completed' : ''"
      :style="progressPosition"
    ></div>
    <div class="clouds">
      <ACol>
        <Transition name="slideRight">
          <img
            src="@/assets/cloud-left.svg"
            class="clouds__left"
            alt=""
            v-if="completed"
          />
        </Transition>
      </ACol>
      <ACol>
        <Transition name="slideLeft">
          <img
            src="@/assets/cloud-right.svg"
            class="clouds__right"
            alt=""
            v-if="completed"
          />
        </Transition>
      </ACol>
    </div>
    <div class="bg-landscape">
      <img src="@/assets/mountain2.svg" alt="" class="mountains" />
    </div>
    <img
      src="@/assets/city.svg"
      alt=""
      class="city"
      :class="completed ? 'city--completed' : ''"
    />
  </div>
  <GroundGrid :completed="completed" />
  <Transition name="pop">
    <div v-if="completed" class="completed">
      <img src="@/assets/bird.png" alt="birds" class="completed__birds" />
      <AText uppercase class="completed__text">Completed!</AText>
    </div>
  </Transition>
  <Transition name="slide">
    <img
      v-if="completed"
      class="type-avatar"
      src="@/assets/ancer-complete-no-bg.gif"
      alt="Asiancer"
    />
  </Transition>
  <img
    v-if="completed"
    src="@/assets/sparkle.png"
    alt="sparkle"
    class="sparkle"
  />
  <img
    v-if="completed"
    src="@/assets/sparkle.png"
    alt="sparkle"
    class="sparkle"
  />
</template>
<script>
import { computed } from "vue";
import AText from "../AText.vue";
import ACol from "../Flex/ACol.vue";
import GroundGrid from "./GroundGrid.vue";

export default {
  name: "grid-background",
  components: { AText, GroundGrid, ACol },
  props: {
    questionNumber: {
      type: Number,
      required: true,
    },
    completed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const skyColor = computed(() => {
      return {
        "background-position": `0 ${6.6 * props.questionNumber}%`,
      };
    });
    const progressPosition = computed(() => {
      return {
        transform: `translate(-50%, ${-2.5 * props.questionNumber}%)`,
      };
    });
    return { progressPosition, skyColor };
  },
};
</script>
<style lang="scss" scoped>
.sky {
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 65%;
  // background-size: 100% 300%;
  transition: background-position 1s ease-in-out, height 600ms ease-in-out;
  &--completed {
    height: 100%;
  }
  background: linear-gradient(
    0deg,
    rgba(42, 54, 66, 1) 9%,
    rgba(228, 56, 82, 1) 33%,
    rgba(237, 126, 140, 1) 50%,
    #ed7e8c 60%,
    #d3a6b8 75%,
    #8ab2d3 90%
  );
  background-size: 100% 300%;
  .sun {
    width: 40%;
    aspect-ratio: 1;
    z-index: 6;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    transition: all 1.5s ease-in-out;
    animation: sunrise 1.5s ease-in-out forwards;
    padding-top: 20px;
    opacity: 0.5;
    &:after{
      content:'';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgba($color: $white, $alpha: 0.3);background: linear-gradient(rgba(228, 56, 82, 1) 0%,
                rgba(250, 247, 240, 1) 100%);
    }
    &:before {
      content: "";
      position: absolute;
      background: white;
      left: 0;
      top: 20px;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: linear-gradient(0deg, #faf7f0 0%, #e43852 100%);
      filter: blur(10px);
    }
    &--completed {
      opacity: 1;
      width: 120vmin !important;
      @include media-breakpoint-down(sm) {
        width: 100vmax !important;
      }
    }
    @include media-breakpoint-down(xl) {
      width: 60%;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  @keyframes sunrise {
    0% {
      top: 100%;
    }
    100% {
      top: 50%;
    }
  }

  .clouds {
    opacity: 0.5;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20vh;
    z-index: 7;
    position: relative;

    &__left {
      width: 40vmin;
    }

    &__right {
      width: 40vmin;
      margin-top: 40vh;
    }

    @include media-breakpoint-down(md) {
      margin-top: 0;

      &__left {
        margin-top: get-vh-mo(100px);
        width: get-vw-mo(90px);
      }

      &__right {
        margin-top: get-vh-mo(270px);
        width: get-vw-mo(90px);
      }
    }
  }

  .slideRight-enter-active {
    animation: slide-right 2.5s;
  }

  @keyframes slide-right {
    0% {
      transform: translateX(-100%);
    }
    60% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  .slideLeft-enter-active {
    animation: slide-left 2.5s;
  }

  @keyframes slide-left {
    0% {
      transform: translateX(100%);
    }
    60% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .bg-landscape {
    position: absolute;
    bottom: -1px;
    display: inherit;
    scale: 1;
    transform-origin: bottom;
    width: 100%;
    mix-blend-mode: overlay;
    z-index: 7;
    .mountains {
      width: 100%;
      opacity: 0.5;
      left: 0;
    }
  }
  .city {
    width: 100%;
    z-index: 9;
    position: absolute;
    bottom: -1px;
    left: 0;
    transition: transform 4.5s ease;
    transform-origin: bottom;
    &--completed {
      transform-origin: unset;
      transform: scale(25);
      transform-origin: 50% 65%;
    }
  }

  .city,
  .mountains {
    @include media-breakpoint-down(md) {
      scale: 3.5;
    }
  }
}

.completed {
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
  &__text {
    font-size: 100px;
    @include media-breakpoint-down(lg) {
      font-size: 75px;
    }
    @include media-breakpoint-down(md) {
      font-size: 45px;
    }
  }
  &__birds {
    max-width: 250px;
    width: 40vw;
  }
}
.pop-enter-active {
  animation: pop-in 1.5s;
}

@keyframes pop-in {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.type-avatar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 50vmin;
  max-height: 45vh;
  @include media-breakpoint-down(sm) {
    height: 75vw;
  }
}

.slide-enter-active {
  animation: slide-in 1.5s;
}

@keyframes slide-in {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.sparkle {
  opacity: 0;
  position: absolute;
  bottom: 30%;
  left: 38%;
  width: get-vw(69px);
  height: auto;
  animation: sparkle 1.2s ease 2s infinite;
  &:last-child {
    bottom: 20%;
    left: auto;
    right: 34%;
    animation: sparkle 1.2s ease 2.5s infinite;
  }
  @include media-breakpoint-down(lg) {
    width: 49px;
    left: 20%;
    &:last-child {
      right: 10%;
    }
  }
}

@keyframes sparkle {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
