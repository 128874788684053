<template>
  <ARow align-center :class="[{ redResult: isResultPage }, 'nav']">
    <router-link to="/" class="nav_link">
      <svg
        alt="Asiance Logo"
        class="nav__image webOnly"
        width="152"
        height="52"
        viewBox="0 0 152 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="logo-wheel"
          d="M140.606 32.9867C140.745 32.7899 140.635 32.4555 140.278 32.0325C135.784 26.5926 133.916 21.9101 134.681 17.9949C132.862 20.6903 132.464 23.5627 133.468 26.6122C134.502 29.6716 136.53 31.8062 139.661 33.026C140.178 33.2031 140.496 33.1932 140.606 32.9867Z"
          fill="#E33D50"
        />
        <path
          id="logo-wheel"
          d="M133.976 15.9582C131.252 14.158 128.349 13.7645 125.278 14.7581C122.186 15.7811 120.019 17.8174 118.796 20.8866C118.598 21.3981 118.607 21.7129 118.826 21.8211C119.035 21.9588 119.363 21.8506 119.79 21.4965C125.278 17.06 130.019 15.2008 133.976 15.9582Z"
          fill="#E33D50"
        />
        <path
          id="logo-wheel"
          d="M130.983 0.14053C130.466 -0.0562119 130.148 -0.0463748 130.039 0.170041C129.899 0.37662 130.009 0.701244 130.367 1.12424C134.86 6.55431 136.738 11.2466 135.973 15.1618C137.792 12.4664 138.19 9.59397 137.176 6.54447C136.152 3.49498 134.084 1.35049 130.983 0.14053Z"
          fill="#E33D50"
        />
        <path
          id="logo-wheel"
          d="M151.858 12.26C152.057 11.7485 152.047 11.4337 151.828 11.3255C151.629 11.1878 151.291 11.296 150.864 11.6501C145.377 16.0965 140.635 17.9557 136.679 17.1982C139.402 18.9984 142.305 19.3919 145.377 18.3983C148.468 17.3753 150.635 15.339 151.858 12.26Z"
          fill="#E33D50"
        />
        <path
          d="M14.4332 45.8313H5.64605L3.39956 50.7497H0L10.2384 29.2072L19.9799 50.7497H16.5902L14.4332 45.8313ZM13.1708 42.9L10.1192 36.0044L6.93828 42.9H13.1708Z"
          fill="white"
        />
        <path
          d="M31.8285 34.9022C31.6396 34.4398 31.3613 34.0168 30.9041 33.6234C30.258 33.102 29.582 32.8955 28.6874 32.8955C26.7789 32.8955 25.7848 34.0267 25.7848 35.335C25.7848 35.9448 26.0035 37.0171 28.0015 37.804L30.0691 38.6303C33.8663 40.155 34.91 42.2895 34.91 44.611C34.91 48.4867 32.1367 51.1426 28.2798 51.1426C25.9041 51.1426 24.4827 50.2573 23.439 49.0966C22.3256 47.8768 21.8386 46.5292 21.7094 45.1324L24.8206 44.4635C24.8206 45.4668 25.1884 46.421 25.6854 47.0604C26.2719 47.7883 27.1367 48.2801 28.3693 48.2801C30.2778 48.2801 31.7589 46.903 31.7589 44.8569C31.7589 42.7814 30.1585 41.9256 28.7967 41.3747L26.8186 40.5484C25.1189 39.85 22.6239 38.4434 22.6239 35.394C22.6239 32.6495 24.7809 30.0231 28.6377 30.0231C30.8544 30.0231 32.1267 30.8494 32.7728 31.4003C33.3295 31.8921 33.916 32.5905 34.3434 33.4463L31.8285 34.9022Z"
          fill="white"
        />
        <path
          d="M42.9915 30.3878V50.74H39.8503V30.3878H42.9915Z"
          fill="white"
        />
        <path
          d="M61.0031 45.8313H52.2159L49.9695 50.7497H46.5798L56.8183 29.2072L66.5597 50.7497H63.1701L61.0031 45.8313ZM59.7407 42.9L56.6891 36.0044L53.5082 42.9H59.7407Z"
          fill="white"
        />
        <path
          d="M70.1379 50.7499V29.0206L85.0682 44.4938V30.3879H88.2093V51.9992L73.2791 36.526V50.7499H70.1379Z"
          fill="white"
        />
        <path
          d="M109.363 35.1492C107.076 33.1032 104.949 32.8868 103.776 32.8868C99.3031 32.8868 96.2813 36.1526 96.2813 40.6381C96.2813 44.9368 99.4224 48.2715 103.806 48.2715C106.271 48.2715 108.19 47.0222 109.353 45.9205V49.6093C107.285 50.829 105.128 51.1339 103.707 51.1339C99.9791 51.1339 97.6332 49.4519 96.4304 48.3305C94.0249 46.0975 93.1302 43.5105 93.1302 40.6381C93.1302 36.8805 94.7008 34.2934 96.4304 32.6704C98.5576 30.6834 101.023 30.0145 103.866 30.0145C105.744 30.0145 107.563 30.3489 109.353 31.4801V35.1492H109.363Z"
          fill="white"
        />
        <path
          d="M125.585 33.3191H117.384V38.4736H125.346V41.4049H117.384V47.8185H125.585V50.7498H114.233V30.3878H125.585V33.3191Z"
          fill="white"
        />
      </svg>

      <img
        class="nav__image mobileOnly"
        src="@/assets/pinwheel.svg"
        alt="Asiance Logo"
      />
    </router-link>
    <ARow justify-end align-center class="langSwitch">
      <AText
        size="20px"
        letterspacing="1px"
        :class="getLocaleClass('en')"
        @click="switchLocale('en')"
        >EN</AText
      >
      <AText
        size="20px"
        letterspacing="1px"
        :class="getLocaleClass('kr')"
        @click="switchLocale('kr')"
        >KR</AText
      >
    </ARow>
    <AShare :white="isResultPage" />
  </ARow>
</template>

<script>
import AShare from "./AShare.vue";
import "@/assets/logo.svg";
import { computed } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import AText from "./AText.vue";
import ARow from "./Flex/ARow.vue";
import { useGetters, useActions } from "vuex-composition-helpers";

export default {
  components: { AShare, AText, ARow },
  setup() {
    const route = useRoute();

    const i18n = useI18n();
    const { currentLang } = useGetters(["currentLang"]);
    const { setLocale } = useActions(["setLocale"]);

    const switchLocale = function (locale) {
      if (currentLang.value === locale) {
        return;
      }
      if (locale === "kr") {
        window.history.pushState(
          {},
          null,
          window.location.pathname.replace("en", "kr")
        );
      }
      if (locale === "en") {
        window.history.pushState(
          {},
          null,
          window.location.pathname.replace("kr", "en")
        );
      }
      setLocale(locale);
      i18n.locale.value = locale;
    };

    const isResultPage = computed(() => {
      return route.name === "locale-result" || route.name === 'locale-type';
    });

    const isHomePage = computed(() => {
      return route.name === "home";
    });

    const getLocaleClass = function (locale) {
      if (locale !== currentLang.value) {
        return "text--deactivated";
      }
    };
    return { isHomePage, isResultPage, switchLocale, getLocaleClass };
  },
};
</script>

<style lang="scss" scoped>
.text--deactivated {
  color: rgba($color: $white, $alpha: 0.3);
  cursor: url("@/assets/cursor.png"), pointer;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 800;

  &_link {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__image {
    position: relative;
    /* path {
      fill: $white;
    } */
    &.webOnly {
      width: get-vw(152px);
      margin-left: get-vw(40px);

      #allWhiteLogo {
        fill: $white;
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &.mobileOnly {
      width: 20px;
      margin-left: 20px;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
    @include media-breakpoint-down(sm) {
      width: get-vw(152px);
    }
  }

  &.redResult {
    background-color: $red;
    position: sticky;
    top: 0;
    z-index: 99;

    #logo-wheel {
      fill: white;
    }

    &:after {
      border-top-width: 2px;
      border-top-style: solid;
      border-image: linear-gradient(to right, white, rgba(0, 0, 0, 0)) 5 7 100%;
      width: 100%;
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.langSwitch {
  flex-grow: 1;
  margin-right: 20px;
  > div {
    &:first-child {
      position: relative;
      margin-right: 15px;
      &:after {
        content: "";
        width: 1px;
        height: 20px;
        background: $white;
        position: absolute;
        right: -15px;
        top: 3px;
        @include media-breakpoint-down(md) {
          height: 16px;
          top: 1px;
        }
      }
    }
    &:last-child {
      margin-left: 15px;
    }
    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }
}
</style>
