import { createStore } from "vuex";
import { ResultPageType } from "@/utils/types";
const results = require("@/misc/results.json");
const ogTagsText = require("@/misc/ogmetatags.json");

const store = createStore({
  state() {
    return {
      locale: "en",
      myType: "",
      currentView: "",
      selectedType: "",
      ogTags: {
        homePage: {
          ogTitle: "What is your work personality? - By Asiance",
          ogDescription:
            "Find out your work personality by taking this quiz made by Asiance",
          ogUrl: "https://eoy.2022.asiance-dev-02.com/",
          ogType: "website",
        },
        resultPage: {
          ogTitle: "I am the",
          ogDescription: "Check out my Work Personality Asiance Quiz results",
          ogUrl: "https://eoy.2022.asiance-dev-02.com/",
          ogType: "website",
        },
        typePage: {
          ogTitle: "This is the",
          ogDescription: "Check out my Work Personality Asiance Quiz results",
          ogUrl: "https://eoy.2022.asiance-dev-02.com/",
          ogType: "website",
        },
      },
      miscText: {
        myTypebtn: "View My Type",
        myPartnerbtn: "Who is My\nPerfect Match?",
        ctaBtn: "GET TO KNOW US",
        shareResultBtn: "SHARE MY RESULT",
        shareTypeBtn: "SHARE THIS TYPE",
        testRedoBtn: "Test Again",
        shareTestTitle: "Share this test",
        shareTestDesc:
          "Share this test with your colleagues, friends and family!\n\nFind out your work personality together!",
        shareMyResultTitle: "Share My Result",
        shareMyResultDesc:
          "Share your test results with your colleagues, friends and family!",
        shareTypeTitle: "Share This Type Result",
        shareTypeDesc:
          "Share this work personality type results with your colleagues, friends and family!",
      },
      allResults: {
        etp: {
          type: "etp",
          partner: "ifp",
          name: "the popular coworker",
          path: "the-popular-coworker",
          subtitle: "Fear of Missing Out? I don't really care",
          description:
            "What even is FOMO? This is an unfamiliar feeling for you since everybody always comes around to you. Bubbly, friendly and also charismatic, what a charming person you are! That's the reason why you're always in the spotlight, whether or not you asked for it. Whoever said the office is a lonely place? Because it never is for you.",
          image: "@/assets/results/etp.gif",
        },
        etj: {
          type: "etj",
          partner: "ifj",
          name: "the employee of the month",
          path: "the-employee-of-the-month",
          subtitle: "Look at my calendar",
          description:
            "Workaholic? Yeah, that's you. You love the thrill of hitting the KPI before anyone else and it almost seems too easy for you. Not only do you perfectly manage your own tasks, but you also enjoy connecting with others and help them achieve their goals. If the office was the school yard, everybody eagerly waits for you to pick them to be on your team. Winner winner chicken dinner! But your wins don't just come from good luck. It's your hard work and persistence that brings you all of your wins.",
          image: "@/assets/results/etj.gif",
        },
        itp: {
          type: "itp",
          partner: "efp",
          name: "the nerd",
          path: "the-nerd",
          subtitle: "Joy of Missing Out. That's exactly what I want",
          description:
            "Glass ceiling? No no no...you want a glass partition that surrounds you at a 360° angle. You feel most secure in your own solitude and perform your best when left to work your magic alone. Although quiet and reserved on the outside, you burn with passion for what you put your mind to. You have great attention to detail and you thrive in your own bubble that may be hard to crack by others.",
          image: "@/assets/results/itp.gif",
        },
        itj: {
          type: "itj",
          partner: "efj",
          name: "the caffeine addict",
          path: "the-caffeine-addict",
          subtitle: "Let me take my coffee first",
          description:
            'You are both a coffee-lover and a coffee-addict. A "coffeeholic"! You want to work more efficiently with a little help of caffeine. But even without all the caffeine flowing in your veins, your brain is hard wired to flood with new ideas and you scurry to grasp all of your flowing thoughts before they slip away. So straight to execution, you go! Work always comes first and you simply do not have time for chitchat or gossip. But we\'re worried about your health;( Take a break, get some sleep, we beg you!',
          image: "@/assets/results/itj.gif",
        },
        efp: {
          type: "efp",
          partner: "itp",
          name: "The Office Oracle",
          path: "the-office-oracle",
          subtitle: "I hear you",
          description:
            "There's just something magical about how people feel when they talk to you. Your outgoing and caring personality makes everyone feel comfortable opening up to you about anything and everything. Every high and low key news find their way to you before anybody else. Even if you didn't ask for it, you behold the ins and outs of the office, just like the Oracle herself.",
          image: "@/assets/results/efp.gif",
        },
        efj: {
          type: "efj",
          partner: "itj",
          name: "the CEO",
          path: "the-ceo",
          subtitle: "Believe in me",
          description:
            "You are passionate, great at organizing events, and a fearless decision maker with great follow through. You are a great speaker and you eat up networking events like a piece of cake. You are not afraid of calling people out on their BS while also able to bring out the best in people. Your charisma and leadership is the subject of admiration (and sometimes even fear).",
          image: "@/assets/results/efj.gif",
        },
        ifp: {
          type: "ifp",
          partner: "etp",
          name: "the misunderstood artist",
          path: "the-misunderstood-artist",
          subtitle: "You don't get it!!",
          description:
            "You are a highly independent and a born creative. No matter how the world tries to fit you into a box, you are strict in your own beliefs and walk your own path unwavered. Though misunderstood from time to time, your sensitive and reserved nature also makes you one of the most diplomatic conflict resolvers in the office as well. Like a true artist, it's your way or the highway and you don't mind enjoying this road alone.",
          image: "@/assets/results/ifp.gif",
        },
        ifj: {
          type: "ifj",
          partner: "etj",
          name: "the intern",
          path: "the-intern",
          subtitle: "MOTIVATIOoOooN",
          description:
            "You bring a youthful and refreshing energy to any room you enter. You can be seen as a timid people pleaser but at the same time, you are the secret glue that keeps the team together. You are humble and accepting of criticism and don't take things personally, so your potential for growth is infinite. Maybe a bit too easy to throw punches at but not to worry, everybody loves you and the office really can't function without you.",
          image: "@/assets/results/ifj.gif",
        },
      },
    };
  },
  mutations: {
    setLocale(state, payload) {
      state.locale = payload;
      state.allTypeResults = results[0][payload].allResults;
      state.miscText = results[0][payload].miscText;
      state.ogTags = ogTagsText[0][payload];
    },
    updateMyType(state, payload) {
      state.myType = payload;
      state.selectedType = payload;
      state.currentView = ResultPageType.MYRESULT;
    },
    updateSelectedType(state, payload) {
      if (
        state.myType !== "" &&
        payload === state.allTypeResults[state.myType].partner
      ) {
        state.currentView = ResultPageType.PERFECTPARTNER;
      }
      if (
        state.myType === "" ||
        (payload !== state.allTypeResults[state.myType].partner &&
          payload !== state.myType)
      ) {
        state.currentView = ResultPageType.OTHER;
      }
      if (payload === state.myType) {
        state.currentView = ResultPageType.MYRESULT;
      }
      state.selectedType = payload;
    },
    updateCurrentView(state, payload) {
      state.currentView = payload;
    },
  },
  actions: {
    setLocale(context, payload) {
      context.commit("setLocale", payload);
    },
    updateMyType(context, payload) {
      // payload must be type string ex) "etf", "etj" etc
      context.commit("updateMyType", payload);
    },
    updateSelectedType(context, payload) {
      // payload must be type string ex) "etf", "etj" etc
      context.commit("updateSelectedType", payload);
    },
    updateCurrentView(context, payload) {
      context.commit("updateCurrentView", payload);
    },
  },
  getters: {
    currentLang(state) {
      return state.locale;
    },
    ogTags(state) {
      return state.ogTags;
    },
    myType(state) {
      return state.myType;
    },
    myTypeResult(state) {
      // myType result Object
      return state.allTypeResults[state.myType];
    },
    myPartnerType(state, getters) {
      // myPartnerType
      if (state.myType === "") {
        return;
      }
      return getters.myTypeResult.partner;
    },
    myPartnerResult(state, getters) {
      // myPartnerResult obj
      const myPartnerType = getters.myTypeResult.partner;
      return state.allTypeResults[myPartnerType];
    },
    allTypes(state) {
      // array of all types in string
      return Object.keys(state.allTypeResults);
    },
    allTypeResults(state) {
      // object of all typesResults
      return state.allTypeResults;
    },
    selectableTypes(state, getters) {
      // array of all selectable types with my type excluded
      const filtered = getters.allTypes.filter((type) => type !== state.myType);

      return filtered.map((type) => state.allTypeResults[type]);
    },
    selectedType(state) {
      return state.selectedType;
    },
    selectedTypeResult(state) {
      return state.allTypeResults[state.selectedType];
    },
    getSelectedAvatar(state) {
      return require(`@/assets/results/${state.selectedType}.gif`);
    },
    currentView(state) {
      return state.currentView;
    },
    pageHeader(state, getters) {
      if (state.locale === "en") {
        if (state.myType !== "" && state.selectedType === state.myType) {
          return "Your type is";
        }
        if (
          state.myType !== "" &&
          state.selectedType === getters.myTypeResult.partner
        ) {
          return "Your Best Partner is";
        }
        return "This type is";
      }

      if (state.locale === "kr") {
        if (state.myType !== "" && state.selectedType === state.myType) {
          return "당신의 타입은";
        }
        if (
          state.myType !== "" &&
          state.selectedType === getters.myTypeResult.partner
        ) {
          return "당신의 베프는";
        }
        return "이 타입은";
      }
    },
    selectedTypeName(_state, getters) {
      return getters.selectedTypeResult.name;
    },
    miscText(state) {
      return state.miscText;
    },
  },
});

export default store;
