<template>
  <ACol class="progress-container">
    <ProgressCursor :style="asiancerWidth" class="progress-container__cursor" />
    <div class="progress-bar">
      <div class="progress-bar__progress" :style="progressWidth"></div>
    </div>

    <div class="progress-steps">
      <AText computerFont size="14px">{{ questionNumber }}/15</AText>
    </div>
  </ACol>
</template>
<script>
import { computed } from "vue";
import ACol from "../Flex/ACol.vue";
import AText from "../AText.vue";
import ProgressCursor from "./ProgressCursor.vue";

export default {
  name: "progress-bar",
  props: {
    questionNumber: {
      type: Number,
      required: true,
    },
    totalAnsweredQuestions: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const progressWidth = computed(() => {
      return {
        width: `${(100 / 15) * props.totalAnsweredQuestions}%`,
      };
    });
    const asiancerWidth = computed(() => {
      return {
        width: `${100 / 15}%`,
        "margin-left": `${(100 / 15) * (props.questionNumber - 1)}%`,
      };
    });
    return { progressWidth, asiancerWidth };
  },
  components: { ACol, ProgressCursor, AText },
};
</script>
<style lang="scss">
.progress-container {
  position: relative;
  z-index: 1;
  width: calc(100% - 50px);
  &__cursor {
    transition: all 500ms ease-in-out;
    margin-bottom: 8px;
    z-index: 1;
    justify-content: center;
  }
}
.progress-bar {
  height: 15px;
  width: 100%;
  border-radius: 50px;
  background: #e438524d;
  position: relative;
  &__progress {
    position: absolute;
    left: 0;
    background: $red;
    border-radius: 50px;
    height: 15px;
    transition: all 500ms ease-in-out;
  }

  @include media-breakpoint-down(sm) {
    height: 10px;
    &__progress {
      height: 10px;
    }
  }
}
.progress-steps {
  position: absolute;
  left: calc(100% + 20px);
  bottom: -2px;
  opacity: 0.8;
}
</style>
