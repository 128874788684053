<template>
  <ANavbar />
  <router-view />
</template>

<script>
import { ref } from "vue";
import ANavbar from "./components/ANavbar.vue";
import { useHead, useSeoMeta } from "@vueuse/head";
import { useGetters } from "vuex-composition-helpers";

export default {
  components: { ANavbar },
  setup() {
    const { ogTags, currentLang } = useGetters(["ogTags", "currentLang"]);

    useHead({
      meta: [
        {
          name: "title",
          content: () => ogTags.value.homePage.ogTitle,
        },
        {
          name: "description",
          content: () => ogTags.value.resultPage.ogDescription,
        },
      ],
    });

    useSeoMeta({
      ogUrl: () => ogTags.value.homePage.ogUrl,
      ogType: () => ogTags.value.homePage.ogType,
      ogDescription: () => ogTags.value.homePage.ogDescription,
      ogTitle: () => ogTags.value.homePage.ogTitle,
      ogImage: () => require(`@/assets/share-imgs/share-result-website-${currentLang.value}.png`),
    });

    let shareModalDisplayed = ref(false);
    const displayModal = function () {};
    return { shareModalDisplayed, displayModal };
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  cursor: url("@/assets/cursor-default.png"), default;
}
a {
  cursor: url("@/assets/cursor.png"), pointer;
}

@import url("https://p.typekit.net/p.css?s=1&k=alk5iiy&ht=tk&f=10879.10881.10884.10886.10887.15586.32874&a=86003888&app=typekit&e=css");
@font-face {
  font-family: "futura-pt";
  src: url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "futura-pt";
  src: url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "futura-pt";
  src: url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "y-computer";
  src: url("@/assets/fonts/Ycomputer.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

#app {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $navy;
  background-color: $navy;
  min-height: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
