<template>
  <ARow space-between align-center class="all-types-wrap">
    <ACol class="selected-avatar-wrap">
      <img
        :src="getSelectedAvatar"
        alt="selected-type-avatar"
        id="selected-type-avatar"
        class="selected-avatar"
      />
      <img
        v-if="selectedType === myPartnerType"
        src="@/assets/hearts.svg"
        alt="hearts"
        class="hearts"
      />
      <img
        v-if="selectedType === myPartnerType"
        src="@/assets/perfect-match.png"
        alt="perfect-match-tape"
        class="perfect-match-tape"
      />
      <img src="@/assets/pin.svg" alt="pin" id="pin" />
    </ACol>
    <ACol class="type-text">
      <ARow class="type-sub">
        <AText computer-font size="1.5rem" class="result-title">
          "{{ selectedTypeResult.subtitle }}"
        </AText>
      </ARow>
      <ARow class="type-desc">
        <p>{{ selectedTypeResult.description }}</p>
      </ARow>
      <!-- <div @click="shareBtnClicked" class="share-link">
        {{ miscText.shareTypeBtn }}
      </div> -->
    </ACol>
  </ARow>
</template>

<script>
import AText from "@/components/AText.vue";
import ACol from "@/components/Flex/ACol.vue";
import ARow from "@/components/Flex/ARow.vue";
import { useGetters, useActions } from "vuex-composition-helpers";

export default {
  name: "all-other-types-view",
  components: { AText, ACol, ARow },
  props: {
    urlToShare: {
      type: String,
    },
  },
  setup(_, context) {
    const {
      myType,
      myTypeResult,
      myPartnerType,
      currentView,
      pageHeader,
      selectedType,
      selectedTypeResult,
      getSelectedAvatar,
      miscText,
    } = useGetters([
      "myType",
      "myTypeResult",
      "myPartnerType",
      "currentView",
      "pageHeader",
      "selectedType",
      "selectedTypeResult",
      "getSelectedAvatar",
      "miscText",
    ]);

    const { updateSelectedType } = useActions(["updateSelectedType"]);

    const shareBtnClicked = () => {
      context.emit("showShareModal");
    };

    return {
      updateSelectedType,
      getSelectedAvatar,
      myType,
      myTypeResult,
      myPartnerType,
      currentView,
      pageHeader,
      selectedType,
      selectedTypeResult,
      miscText,
      shareBtnClicked,
    };
  },
};
</script>

<style lang="scss" scoped>
.all-types-wrap {
  max-width: 1200px;
  width: 90vw;
  margin: 90px auto;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    margin-bottom: 30px;
  }

  #perfect-match-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    cursor: url("@/assets/cursor.png"), pointer;

    @include media-breakpoint-down(lg) {
      position: absolute;
      top: 26px;
      left: 20px;
      font-size: 12px;
    }
  }
}

.selected-avatar-wrap {
  position: relative;
  width: 320px;
  margin: 0 90px;

  .selected-avatar {
    width: 100%;
    border-radius: 50%;
    box-shadow: rgb(0 0 0 / 30%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
  }

  #pin {
    position: absolute;
    top: 6px;
    left: 0;
    right: 0;
    margin: auto;
    width: 12px;
    height: auto;
  }

  .hearts {
    position: absolute;
    top: -18%;
    right: 0;
    width: 40%;
  }

  .perfect-match-tape {
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
  }

  @include media-breakpoint-down(lg) {
    width: 220px;
  }
}

.type-text {
  width: 700px;
  max-width: 100%;
  .type-desc {
    text-align: left;
    font-size: 20px;
    letter-spacing: 0.05em;
  }

  @include media-breakpoint-down(lg) {
    margin-top: 40px;
    text-align: center;

    .type-sub {
      justify-content: center;
    }

    .type-desc {
      text-align: center;
    }
  }
}

.result-title,
p {
  letter-spacing: 0.05em;
}

.share-link {
  width: fit-content;
  cursor: url("@/assets/cursor.png"), pointer;
  letter-spacing: 0.05em;
  font-size: 18px;
  text-decoration: underline;
  transform: translateY(30px);
}
</style>
