<template>
  <swiper
    :slidesPerView="2"
    :spaceBetween="260"
    :centeredSlides="true"
    class="mySwiper"
    @slideChange="slideChange"
    @swiper="onSwiper"
  >
    <swiper-slide>
      <QuestionChoice
        v-bind="bindQuestion(0)"
        @choice-clicked="choiceSelected(0)"
        firstChoice
      />
    </swiper-slide>
    <swiper-slide>
      <QuestionChoice
        v-bind="bindQuestion(1)"
        @choice-clicked="choiceSelected(1)"
    /></swiper-slide>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import QuestionChoice from "./QuestionChoice.vue";
import { ref, watch } from "vue";
export default {
  name: "QuizCarousel",
  components: {
    Swiper,
    SwiperSlide,
    QuestionChoice,
  },
  props: {
    bindQuestion: {
      type: Function,
      required: true,
    },
    choiceClick: {
      type: Function,
      required: true,
    },
    initialSlide: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    let mySwiper = null;
    const onSwiper = (swiper) => {
      mySwiper = swiper;
    };

    const firstSlide = ref(true);
    const slideChange = function () {
      firstSlide.value = !firstSlide.value;
    };
    const choiceSelected = function (index) {
      if (
        (firstSlide.value && index === 1) ||
        (!firstSlide.value && index === 0)
      ) {
        return;
      }
      if (index === 1) {
        setTimeout(() => {
          mySwiper.slidePrev();
          firstSlide.value = true;
        }, 500);
      }
      props.choiceClick(index);
    };

    watch(
      () => props.initialSlide,
      (newVal) => {
        if (newVal === null) {
          return;
        }
        if (firstSlide.value && newVal === 1) {
          mySwiper.slideNext();
          firstSlide.value = false;
        }
        if (!firstSlide.value && newVal === 0) {
          mySwiper.slidePrev();
          firstSlide.value = true;
        }
      }
    );

    return { slideChange, choiceSelected, onSwiper };
  },
};
</script>
<style lang="scss" scoped>
.swiper {
  width: 100%;
  height: 100%;
  flex-direction: column;
  @include media-breakpoint-down(sm) {
    padding-top: 20px;
  }
}

.swiper-pagination {
  position: relative;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  // background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &-next {
    transform: translateY(10%) rotate(8deg) !important;
    opacity: 0.7;
    transition: all 300ms ease-in-out;
  }
  &-prev {
    transform: translateY(10%) rotate(-8deg) !important;
    opacity: 0.7;
    transition: all 300ms ease-in-out;
  }
  &-active {
    z-index: 3;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.active-bullet {
  background: white;
  opacity: 1;
  margin: 0 15px !important;
}
</style>
