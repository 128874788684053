<template>
  <div class="ground" :class="completed? 'ground--completed' : ''">
    <div class="right-lines">
      <div
        v-for="n in 8"
        :key="'rightlines' + n"
        class="line vertical-line"
      ></div>
    </div>
    <div class="left-lines">
      <div
        v-for="n in 8"
        :key="'leftlines' + n"
        class="line vertical-line"
      ></div>
    </div>
    <div class="horizontal-lines">
      <div v-for="n in 12" :key="n" class="line horizontal-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ground-grid",
  props: {
    completed: {
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
.ground {
  background-color: $navy;
  border-top: 2px solid #4c6278;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 35%;
  transition: all 600ms ease-in-out;
  &--completed {
    height: 0;
    border: none;
  }
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgb(42, 54, 66);
    background: linear-gradient(
      0deg,
      rgba(42, 54, 66, 1) 0%,
      rgba(42, 54, 66, 0) 100%
    );
    left: 0;
    top: 0;
  }
  .line {
    /* background-color: rgba($color: $white, $alpha: 0.3); */
    background-color: #4c6278;
  }

  $angle: 16deg;
  .vertical-line {
    position: absolute;
    top: 0px;
    width: 2px;
    height: 1000%;
    transform-origin: top left;
  }

  .right-lines {
    .vertical-line {
      left: 50%;
      &:nth-child(1) {
        left: calc(50% + get-vw(45px));
        transform: rotate(-$angle * 1.1);
      }
      &:nth-child(2) {
        left: calc(50% + get-vw(150px));
        transform: rotate(-$angle * 2.4);
      }
      &:nth-child(3) {
        left: calc(50% + get-vw(250px));
        transform: rotate(-$angle * 3.15);
      }
      &:nth-child(4) {
        left: calc(50% + get-vw(350px));
        transform: rotate(-$angle * 3.6);
      }
      &:nth-child(5) {
        left: calc(50% + get-vw(450px));
        transform: rotate(-$angle * 3.975);
      }
      &:nth-child(6) {
        left: calc(50% + get-vw(550px));
        transform: rotate(-$angle * 4.225);
      }
      &:nth-child(7) {
        left: calc(50% + get-vw(650px));
        transform: rotate(-$angle * 4.475);
      }
      &:nth-child(8) {
        left: calc(50% + get-vw(750px));
        transform: rotate(-$angle * 4.725);
      }
    }
  }
  .left-lines {
    .vertical-line {
      right: 50%;
      &:nth-child(1) {
        right: calc(50% + get-vw(45px));
        transform: rotate($angle * 1);
      }

      &:nth-child(2) {
        right: calc(50% + get-vw(150px));
        transform: rotate($angle * 2.3);
      }

      &:nth-child(3) {
        right: calc(50% + get-vw(250px));
        transform: rotate($angle * 3.15);
      }

      &:nth-child(4) {
        right: calc(50% + get-vw(350px));
        transform: rotate($angle * 3.6);
      }

      &:nth-child(5) {
        right: calc(50% + get-vw(450px));
        transform: rotate($angle * 3.975);
      }

      &:nth-child(6) {
        right: calc(50% + get-vw(550px));
        transform: rotate($angle * 4.225);
      }
      &:nth-child(7) {
        right: calc(50% + get-vw(650px));
        transform: rotate($angle * 4.475);
      }
      &:nth-child(8) {
        right: calc(50% + get-vw(750px));
        transform: rotate($angle * 4.725);
      }
    }
  }

  $time-wave: 8s;

  .horizontal-line {
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    animation: infinite walking $time-wave cubic-bezier(1, 0.01, 1, 0.9);

    @for $i from 1 through 13 {
      &:nth-child(1n + #{$i}) {
        animation-delay: -$time-wave/ 12 * $i;
      }
    }
  }
}

@keyframes walking {
  100% {
    transform: translate(0, 65vh);
  }
}
</style>
