<template>
  <a-flex v-bind="$props" class="a-row">
    <slot />
  </a-flex>
</template>
<script>
// import { computed } from "vue";
import AFlex from "./AFlex.vue";
export default {
  name: "a-row",
  components: { AFlex },
};
</script>
<style lang="scss">
.a-row {
  flex-direction: row;
}
</style>
